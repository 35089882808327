import React from 'react';
import { motion } from 'framer-motion';
import { HiAtSymbol, HiCheck, HiX } from 'react-icons/hi';

const ProfileBanner = ({ 
  user, 
  connectionButton, 
  connectionStatus, 
  connectionId, 
  isRequestReceiver,
  isProcessing,
  handleAccept,
  handleDecline
}) => {
  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const cardHover = {
    scale: 1.02,
    transition: { duration: 0.2 }
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
      {/* Banner */}
      <div className="h-32 bg-gradient-to-r from-blue-500 to-purple-600"></div>
      
      {/* Profile Info */}
      <div className="px-6 pb-6">
        {/* Avatar */}
        <motion.div 
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 15 }}
          className="relative -mt-16 mb-4"
        >
          <div className="w-32 h-32 mx-auto rounded-full ring-4 ring-white dark:ring-gray-800 bg-gradient-to-br from-blue-500 to-purple-600 flex items-center justify-center shadow-lg">
            <span className="text-white text-4xl font-bold">
              {getInitials(user.name)}
            </span>
          </div>
        </motion.div>

        {/* Name & Username */}
        <motion.div variants={fadeInUp} className="text-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
            {user.name}
          </h1>
          <div className="flex items-center justify-center text-gray-500 dark:text-gray-400 mb-2">
            <HiAtSymbol className="w-4 h-4 mr-1" />
            <span>{user.username}</span>
          </div>
          {user.role && (
            <p className="text-gray-500 dark:text-gray-400 font-medium">
              {user.role}
            </p>
          )}
        </motion.div>

        {/* Connection Button */}
        {connectionButton && (
          <motion.button
            variants={fadeInUp}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={connectionButton.onClick}
            disabled={isProcessing || !connectionButton.onClick}
            className={connectionButton.classes}
          >
            <connectionButton.icon className="w-5 h-5" />
            {connectionButton.text}
          </motion.button>
        )}

        {/* Pending Connection Actions - Only show for request receiver */}
        {connectionStatus === 'pending' && connectionId && isRequestReceiver && (
          <motion.div
            variants={fadeInUp}
            className="flex justify-center gap-2 mt-2"
          >
            <button
              onClick={handleAccept}
              disabled={isProcessing}
              className="flex items-center gap-1 px-4 py-1.5 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium shadow-sm transition-colors duration-200"
            >
              <HiCheck className="w-4 h-4" />
              Accept
            </button>
            <button
              onClick={handleDecline}
              disabled={isProcessing}
              className="flex items-center gap-1 px-4 py-1.5 bg-red-500 hover:bg-red-600 text-white rounded-lg font-medium shadow-sm transition-colors duration-200"
            >
              <HiX className="w-4 h-4" />
              Decline
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ProfileBanner;
