import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaGithub, 
  FaLinkedin, 
  FaTwitter, 
  FaGlobe, 
  FaInstagram, 
  FaYoutube, 
  FaFacebook, 
  FaTiktok, 
  FaMedium, 
  FaReddit, 
  FaSoundcloud 
} from 'react-icons/fa';

const ProfileSocialLinks = ({ socialLinks }) => {
  if (!socialLinks?.length) return null;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const getSocialIcon = (url) => {
    if (!url) return { icon: FaGlobe, color: 'text-gray-600 dark:text-gray-400 hover:text-gray-500' };
    
    try {
      const domain = new URL(url).hostname.toLowerCase();
      
      const socialIcons = {
        'github.com': { icon: FaGithub, color: 'text-gray-700 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white' },
        'linkedin.com': { icon: FaLinkedin, color: 'text-gray-700 dark:text-gray-400 hover:text-blue-600' },
        'twitter.com': { icon: FaTwitter, color: 'text-gray-700 dark:text-gray-400 hover:text-blue-400' },
        'instagram.com': { icon: FaInstagram, color: 'text-gray-700 dark:text-gray-400 hover:text-pink-600' },
        'youtube.com': { icon: FaYoutube, color: 'text-gray-700 dark:text-gray-400 hover:text-red-600' },
        'facebook.com': { icon: FaFacebook, color: 'text-gray-700 dark:text-gray-400 hover:text-blue-800' },
        'tiktok.com': { icon: FaTiktok, color: 'text-gray-700 dark:text-gray-400 hover:text-black dark:hover:text-white' },
        'medium.com': { icon: FaMedium, color: 'text-gray-700 dark:text-gray-400 hover:text-gray-800 dark:hover:text-white' },
        'reddit.com': { icon: FaReddit, color: 'text-gray-700 dark:text-gray-400 hover:text-orange-600' },
        'soundcloud.com': { icon: FaSoundcloud, color: 'text-gray-700 dark:text-gray-400 hover:text-orange-500' }
      };

      for (const [key, value] of Object.entries(socialIcons)) {
        if (domain.includes(key)) return value;
      }

      return { icon: FaGlobe, color: 'text-gray-700 dark:text-gray-400 hover:text-blue-500' };
    } catch {
      return { icon: FaGlobe, color: 'text-gray-700 dark:text-gray-400 hover:text-blue-500' };
    }
  };

  return (
    <motion.div 
      variants={staggerContainer}
      className="flex flex-wrap justify-center gap-3"
    >
      {socialLinks.map((social, index) => {
        if (!social?.url) return null;
        const { icon: Icon, color } = getSocialIcon(social.url);
        return (
          <motion.a
            key={index}
            variants={fadeInUp}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`p-2.5 rounded-lg bg-gray-50 dark:bg-gray-700/50 transition-all duration-200 hover:shadow-md ${color}`}
          >
            <Icon className="w-5 h-5" />
          </motion.a>
        );
      })}
    </motion.div>
  );
};

export default ProfileSocialLinks;
