import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { authApi } from '../services/api';

const ModulesContext = createContext();

export const useModules = () => {
  const context = useContext(ModulesContext);
  if (!context) {
    throw new Error('useModules must be used within a ModulesProvider');
  }
  return context;
};

export const ModulesProvider = ({ children }) => {
  const { user } = useAuth();
  const [modules, setModules] = useState({
    resume: false,
    payment: false,
    extraLinks: false,
    // Add more modules here as they're created
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchModules = async () => {
      if (!user) {
        setModules({
          resume: false,
          payment: false,
          extraLinks: false,
          // Set other modules to false when logged out
        });
        setIsLoading(false);
        return;
      }

      try {
        const response = await authApi.get('/api/user/modules');
        setModules(response.data?.modules || {
          resume: false,
          payment: false,
          extraLinks: false,
          // Default other modules to false if not returned
        });
      } catch (error) {
        console.error('Error fetching modules:', error);
        setModules({
          resume: false,
          payment: false,
          extraLinks: false,
          // Set other modules to false on error
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchModules();
  }, [user]);

  const toggleModule = async (moduleName) => {
    if (!user) return;

    try {
      const updatedModules = {
        ...modules,
        [moduleName]: !modules[moduleName]
      };

      await authApi.put('/api/user/modules', {
        modules: updatedModules
      });

      setModules(updatedModules);
    } catch (error) {
      console.error(`Error toggling ${moduleName} module:`, error);
    }
  };

  return (
    <ModulesContext.Provider value={{ modules, toggleModule, isLoading }}>
      {children}
    </ModulesContext.Provider>
  );
};
