export const getColors = (isDarkMode) => ({
    you: {
        primary: '#6366F1',
        gradient: ctx => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 12);
            gradient.addColorStop(0, '#818CF8');
            gradient.addColorStop(1, '#4F46E5');
            return gradient;
        }
    },
    connected: {
        primary: '#EC4899',
        gradient: ctx => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 8);
            gradient.addColorStop(0, '#F472B6');
            gradient.addColorStop(1, '#DB2777');
            return gradient;
        }
    },
    secondary: {
        primary: isDarkMode ? '#10B981' : '#059669', // Changed to green for better visibility
        gradient: ctx => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 8);
            gradient.addColorStop(0, isDarkMode ? '#34D399' : '#10B981');
            gradient.addColorStop(1, isDarkMode ? '#059669' : '#047857');
            return gradient;
        }
    },
    expanded: { // New color for expanded nodes
        primary: isDarkMode ? '#F59E0B' : '#D97706',
        gradient: ctx => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 8);
            gradient.addColorStop(0, isDarkMode ? '#FBBF24' : '#F59E0B');
            gradient.addColorStop(1, isDarkMode ? '#D97706' : '#B45309');
            return gradient;
        }
    },
    other: {
        primary: isDarkMode ? '#4B5563' : '#9CA3AF',
        gradient: ctx => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 8);
            gradient.addColorStop(0, isDarkMode ? '#6B7280' : '#D1D5DB');
            gradient.addColorStop(1, isDarkMode ? '#374151' : '#6B7280');
            return gradient;
        }
    }
});

export const getLinkColors = (isDarkMode) => ({
    direct: isDarkMode ? '#F472B6' : '#EC4899',
    secondary: isDarkMode ? '#34D399' : '#10B981',
    unselected: isDarkMode ? '#4B5563' : '#E5E7EB' // New gray color for unselected connections
});
