import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FaSave, FaDollarSign } from 'react-icons/fa';
import { authApi } from '../../services/api';

const PaymentLinks = () => {
  const { user } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLinks, setPaymentLinks] = useState([]);

  useEffect(() => {
    const fetchPaymentLinks = async () => {
      if (!user?.username) {
        setIsLoading(false);
        return;
      }

      try {
        const links = await authApi.getPaymentLinks(user.username);
        setPaymentLinks(links || []);
      } catch (error) {
        console.error('Error fetching payment links:', error);
        setPaymentLinks([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentLinks();
  }, [user]);

  const handleAddLink = () => {
    setPaymentLinks([...paymentLinks, { platform: '', username: '' }]);
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...paymentLinks];
    newLinks.splice(index, 1);
    setPaymentLinks(newLinks);
  };

  const handleUpdateLink = (index, field, value) => {
    const newLinks = [...paymentLinks];
    newLinks[index] = { ...newLinks[index], [field]: value };
    setPaymentLinks(newLinks);
  };

  const validateLinks = () => {
    const errors = [];
    paymentLinks.forEach((link, index) => {
      if (link.platform && !link.username) {
        errors.push(`Payment link ${index + 1}: Username is required`);
      }
      if (!link.platform && link.username) {
        errors.push(`Payment link ${index + 1}: Platform is required`);
      }
    });
    return errors;
  };

  const handleSave = async () => {
    if (!user?.username) {
      setSaveStatus('Please log in to save your payment links');
      return;
    }

    const validationErrors = validateLinks();
    if (validationErrors.length > 0) {
      setSaveStatus(`Validation errors: ${validationErrors[0]}`);
      return;
    }

    setIsSaving(true);
    setSaveStatus('Saving...');

    try {
      // Filter out incomplete entries and normalize platform names
      const validLinks = paymentLinks
        .filter(link => link.platform && link.username)
        .map(link => ({
          platform: link.platform.toLowerCase().replace(/\s+/g, ''),
          username: link.username
        }));

      const updatedLinks = await authApi.updatePaymentLinks(user.username, validLinks);
      setPaymentLinks(updatedLinks);
      
      setSaveStatus('Payment links saved successfully!');

      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } catch (error) {
      console.error('Error saving payment links:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error ||
                          'Error saving payment links. Please try again.';
      setSaveStatus(errorMessage);
      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } finally {
      setIsSaving(false);
    }
  };

  const getPlatformIcon = (platform) => {
    if (!platform) return null;
    return (
      <div className="flex items-center space-x-2 text-gray-500">
        <FaDollarSign className="w-4 h-4" />
        <span className="text-sm capitalize">{platform}</span>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-3 sm:p-6">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Payment Links
        </h2>
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`w-full sm:w-auto group relative inline-flex items-center justify-center px-6 py-2.5 rounded-lg text-white font-medium shadow-lg transition-all duration-200 ease-in-out ${
            isSaving 
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 active:from-blue-700 active:to-blue-800'
          }`}
        >
          <FaSave className={`w-5 h-5 mr-2 ${isSaving ? 'animate-spin' : ''}`} />
          {isSaving ? 'Saving...' : 'Save Links'}
        </button>
      </div>

      {/* Payment Links List */}
      <div className="space-y-3 sm:space-y-4">
        {paymentLinks.map((link, index) => (
          <div key={index} className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 bg-gray-50 dark:bg-gray-700 p-3 sm:p-4 rounded-lg">
            <div className="flex-grow grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Platform
                </label>
                <select
                  value={link.platform}
                  onChange={(e) => handleUpdateLink(index, 'platform', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white text-base"
                >
                  <option value="">Select Platform</option>
                  <option value="paypal">PayPal</option>
                  <option value="cashapp">Cash App</option>
                  <option value="venmo">Venmo</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Username
                </label>
                <div className="relative">
                  {link.platform && (
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                      {getPlatformIcon(link.platform)}
                    </div>
                  )}
                  <input
                    type="text"
                    value={link.username}
                    onChange={(e) => handleUpdateLink(index, 'username', e.target.value)}
                    className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white text-base ${
                      link.platform ? 'pl-24' : ''
                    }`}
                    placeholder="Enter username"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => handleRemoveLink(index)}
              className="w-full sm:w-auto flex items-center justify-center p-2 text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 border border-red-200 dark:border-red-700 rounded-md hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors"
            >
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
              <span className="ml-2 sm:hidden">Remove</span>
            </button>
          </div>
        ))}

        {/* Add Link Button */}
        <button
          onClick={handleAddLink}
          className="w-full py-3 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg text-gray-600 dark:text-gray-400 hover:border-blue-500 hover:text-blue-500 dark:hover:border-blue-400 dark:hover:text-blue-400 transition-colors duration-200 text-base"
        >
          + Add Payment Link
        </button>
      </div>

      {/* Toast Notification */}
      {saveStatus && (
        <div className="fixed bottom-0 inset-x-0 sm:bottom-4 sm:right-4 sm:left-auto z-50 p-3 sm:p-0">
          <div 
            className={`
              px-4 py-3 rounded-lg shadow-lg max-w-md mx-auto sm:mx-0
              transform transition-all duration-300 ease-in-out
              translate-y-0 opacity-100
              ${saveStatus.includes('Error') || saveStatus.includes('Validation') 
                ? 'bg-red-500 text-white' 
                : saveStatus === 'Saving...' 
                  ? 'bg-yellow-500 text-white'
                  : 'bg-green-500 text-white'
              }
            `}
          >
            <div className="flex items-center space-x-2">
              <svg 
                className="w-5 h-5 flex-shrink-0" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                {saveStatus.includes('Error') || saveStatus.includes('Validation') ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                ) : saveStatus === 'Saving...' ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                )}
              </svg>
              <span className="font-medium text-sm sm:text-base">{saveStatus}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentLinks;
