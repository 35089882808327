import React from 'react';
import { motion } from 'framer-motion';
import { HiOfficeBuilding } from 'react-icons/hi';

const ProfileAboutSection = ({ company, about }) => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  if (!company && !about) return null;

  return (
    <div className="mt-6">
      {/* Company */}
      {company && (
        <motion.div 
          variants={fadeInUp}
          className="flex items-center justify-center gap-3 px-4 py-2.5 bg-gray-50 dark:bg-gray-700/50 rounded-lg text-gray-700 dark:text-gray-300 mb-6"
        >
          <HiOfficeBuilding className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          <span className="text-sm font-medium">{company}</span>
        </motion.div>
      )}

      {/* About Section */}
      {about && (
        <motion.div variants={fadeInUp}>
          <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 pb-2">
            About
          </h3>
          <p className="mt-4 text-gray-600 dark:text-gray-400 leading-relaxed">
            {about}
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default ProfileAboutSection;
