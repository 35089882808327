import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HiUserAdd } from 'react-icons/hi';
import { authApi, resumeApi, connectionApi, handleApiError } from '../services/api';
import ProfileSkeleton from '../components/profile/ProfileSkeleton';
import ProfileBanner from '../components/profile/ProfileBanner';
import ProfileAboutSection from '../components/profile/ProfileAboutSection';
import ProfileSocialLinks from '../components/profile/ProfileSocialLinks';
import ProfilePaymentLinks from '../components/profile/ProfilePaymentLinks';
import ProfileResume from '../components/profile/ProfileResume';
import ProfileTeams from '../components/profile/ProfileTeams';
import ProfileExtraLinks from '../components/profile/ProfileExtraLinks';
import { useAuth } from '../contexts/AuthContext';
import { useAuthModal } from '../contexts/AuthModalContext';

const UserProfile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const onAuthClick = useAuthModal();
  const [user, setUser] = useState(null);
  const [userModules, setUserModules] = useState({});
  const [resumeData, setResumeData] = useState(null);
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [extraLinks, setExtraLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('none');
  const [connectionId, setConnectionId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRequestReceiver, setIsRequestReceiver] = useState(false);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  // Connection handlers
  const handleConnect = async () => {
    try {
      setIsProcessing(true);
      const response = await connectionApi.sendRequest(user.id);
      setConnectionStatus('pending');
      setConnectionId(response.connection.id);
      setIsRequestReceiver(false);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAccept = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.acceptRequest(connectionId);
      setConnectionStatus('connected');
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDecline = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.declineRequest(connectionId);
      setConnectionStatus('none');
      setConnectionId(null);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.removeConnection(connectionId);
      setConnectionStatus('none');
      setConnectionId(null);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLoginRedirect = () => {
    if (onAuthClick) {
      onAuthClick('login');
    } else {
      navigate('/login', { state: { from: `/user/${username}` } });
    }
  };

  // Get connection button configuration based on status and auth state
  const getConnectionButton = () => {
    const baseClasses = "w-full sm:w-auto mx-auto flex items-center justify-center gap-2 px-6 py-2.5 rounded-lg font-medium shadow-sm transition-all duration-200";
    
    if (!currentUser) {
      return {
        onClick: handleLoginRedirect,
        icon: HiUserAdd,
        text: "Add Connection",
        classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
      };
    }

    if (currentUser?.username === username) {
      return null;
    }
    
    switch (connectionStatus) {
      case 'connected':
        return {
          onClick: handleRemove,
          icon: HiUserAdd,
          text: "Remove Connection",
          classes: `${baseClasses} bg-red-500 hover:bg-red-600 text-white`
        };
      case 'pending':
        return {
          onClick: null,
          icon: HiUserAdd,
          text: "Connection Pending",
          classes: `${baseClasses} bg-yellow-500 hover:bg-yellow-600 text-white cursor-not-allowed`
        };
      default:
        return {
          onClick: handleConnect,
          icon: HiUserAdd,
          text: "Add Connection",
          classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
        };
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch user data, social links, and modules in parallel
        const [userProfile, socialLinksData, modulesData] = await Promise.all([
          authApi.getUserProfile(username),
          authApi.getUserSocialLinks(username),
          authApi.get(`/api/users/${username}/modules`)
        ]);

        // Format social links
        const formattedSocialLinks = (Array.isArray(socialLinksData) ? socialLinksData : [])
          .filter(link => link?.url?.trim())
          .map(link => ({
            url: link.url.trim().startsWith('http') ? link.url.trim() : `https://${link.url.trim()}`
          }));

        setUser({
          ...userProfile,
          socialLinks: formattedSocialLinks
        });

        setUserModules(modulesData.data?.modules || {});

        // Only fetch connection status if user is logged in
        if (currentUser) {
          try {
            const connectionsData = await connectionApi.getConnections();
            const connection = connectionsData.connections.find(
              conn => conn.requester_id === userProfile.id || conn.requested_id === userProfile.id
            );
            
            if (connection) {
              setConnectionId(connection.id);
              setConnectionStatus(connection.status === 'accepted' ? 'connected' : 'pending');
              setIsRequestReceiver(connection.requested_id === userProfile.id);
            }
          } catch (error) {
            console.error('Error fetching connection status:', error);
          }
        }

        // Fetch resume data if resume module is enabled
        if (modulesData.data?.modules?.resume) {
          try {
            const resume = await resumeApi.getResume(username);
            if (resume.data && Object.values(resume.data).some(section => section?.length > 0)) {
              // Transform projects and education data to match the expected structure
              const transformedData = {
                ...resume.data,
                projects: resume.data.projects?.map((proj, index) => ({
                  id: index,
                  title: proj.title || proj.name, // Handle both title and name fields
                  description: proj.description || '',
                  technologies: proj.technologies || '',
                  link: proj.link || ''
                })) || [],
                education: resume.data.education?.map((edu, index) => ({
                  id: index,
                  school: edu.school || edu.institution, // Handle both school and institution fields
                  degree: edu.degree || '',
                  fieldOfStudy: edu.fieldOfStudy || '',
                  startDate: edu.startDate || '',
                  endDate: edu.endDate || edu.graduationDate || '', // Handle both endDate and graduationDate fields
                  description: edu.description || ''
                })) || [],
                skills: resume.data.skills?.map((skill, index) => ({
                  id: index,
                  name: typeof skill === 'string' ? skill : skill.name, // Handle both string and object format
                  category: typeof skill === 'string' ? '' : (skill.category || ''),
                  rating: typeof skill === 'string' ? 0 : (skill.proficiencyLevel || 0)
                })) || []
              };
              setResumeData(transformedData);
            }
          } catch (error) {
            console.error('Error fetching resume:', error);
          }
        }

        // Fetch payment links if payment module is enabled
        if (modulesData.data?.modules?.payment) {
          try {
            const paymentData = await authApi.get(`/api/user/${username}/payment-links`);
            setPaymentLinks(paymentData.data?.paymentLinks || []);
          } catch (error) {
            console.error('Error fetching payment links:', error);
          }
        }

        // Fetch extra links if extraLinks module is enabled
        if (modulesData.data?.modules?.extraLinks) {
          try {
            const extraLinksData = await authApi.getExtraLinks(username);
            setExtraLinks(Array.isArray(extraLinksData) ? extraLinksData : []);
          } catch (error) {
            console.error('Error fetching extra links:', error);
          }
        }
      } catch (err) {
        setError(handleApiError(err));
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username, currentUser]);

  if (loading) {
    return <ProfileSkeleton />;
  }

  if (error) {
    return (
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
        className="flex justify-center items-center min-h-[80vh]"
      >
        <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-4 rounded-lg shadow-sm">
          {error}
        </div>
      </motion.div>
    );
  }

  if (!user) {
    return (
      <motion.div 
        initial="hidden"
        animate="visible"
        variants={fadeInUp}
        className="flex justify-center items-center min-h-[80vh]"
      >
        <div className="bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-6 py-4 rounded-lg shadow-sm">
          User not found
        </div>
      </motion.div>
    );
  }

  const connectionButton = getConnectionButton();

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={staggerContainer}
      className="max-w-2xl mx-auto px-4 py-8"
    >
      <ProfileBanner 
        user={user}
        connectionButton={connectionButton}
        connectionStatus={connectionStatus}
        connectionId={connectionId}
        isRequestReceiver={isRequestReceiver}
        isProcessing={isProcessing}
        handleAccept={handleAccept}
        handleDecline={handleDecline}
      />

      <ProfileAboutSection 
        company={user.company}
        about={user.about}
      />

      <ProfileSocialLinks 
        socialLinks={user.socialLinks}
      />

      <ProfilePaymentLinks 
        paymentLinks={paymentLinks}
      />

      <ProfileExtraLinks 
        extraLinks={extraLinks}
        userModules={userModules}
      />

      <ProfileResume 
        resumeData={resumeData}
        userModules={userModules}
      />

      <ProfileTeams 
        teams={user.teams}
      />
    </motion.div>
  );
};

export default UserProfile;
