import axios from 'axios';

// Configure axios defaults based on environment
const hostname = window.location.hostname;
const baseURL = (hostname === 'agreebits.com' || hostname === 'www.agreebits.com')
  ? 'https://dev.agreebits.com'
  : 'http://localhost:8000';

// Configure axios defaults
const api = axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true // Important for CORS with credentials
});

// Create a separate instance for public requests without auth
const publicApi = axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

// Add request interceptor for error handling
api.interceptors.request.use(
  config => {
    // Add authorization header if token exists
    const token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    
    // Handle 401 responses
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      // Don't redirect if this is a login attempt
      if (!originalRequest.url.includes('/api/login')) {
        // Clear tokens on auth failure
        localStorage.removeItem('auth_token');
        sessionStorage.removeItem('auth_token');
        
        // Only redirect to login if we're not already there
        if (!window.location.pathname.includes('/login')) {
          window.location.href = '/about';
        }
      }
    }
    return Promise.reject(error);
  }
);

// Authentication related API calls
export const authApi = {
  // Test API connection
  test: () => api.get('/api/test'),

  // Get CSRF cookie
  getCsrfCookie: async () => {
    try {
      await api.get('/api/csrf-cookie');
    } catch (error) {
      console.error('Failed to get CSRF cookie:', error);
    }
  },

  // Register new user
  register: async (userData) => {
    try {
      // Get CSRF cookie first
      await authApi.getCsrfCookie();

      // Create the registration data with the correct field names
      const registrationData = {
        name: `${userData.firstName} ${userData.lastName}`.trim(),
        username: userData.username || undefined, // Only include if provided
        email: userData.email,
        password: userData.password,
        password_confirmation: userData.password
      };
      
      const response = await api.post('/api/register', registrationData);
      
      // Store token if provided in response
      if (response.data.token) {
        sessionStorage.setItem('auth_token', response.data.token);
        setAuthToken(response.data.token);
      }
      
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Login user
  login: async (email, password, remember = false) => {
    try {
      // Get CSRF cookie first
      await authApi.getCsrfCookie();

      const data = { email, password, remember };
      const response = await api.post('/api/login', data);
      
      if (response.data.token) {
        // Store token based on remember preference
        if (remember) {
          localStorage.setItem('auth_token', response.data.token);
          sessionStorage.removeItem('auth_token');
        } else {
          sessionStorage.setItem('auth_token', response.data.token);
          localStorage.removeItem('auth_token');
        }
        
        // Set the token in axios headers
        setAuthToken(response.data.token);
      }
      
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Logout user
  logout: async () => {
    try {
      const token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
      if (token) {
        await api.post('/api/logout');
      }
    } catch (error) {
      throw error;
    } finally {
      // Always clear tokens regardless of logout success
      localStorage.removeItem('auth_token');
      sessionStorage.removeItem('auth_token');
      setAuthToken(null);
    }
  },

  // Get user profile
  get: async (url) => {
    try {
      const response = await api.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Update user profile
  put: async (url, data) => {
    try {
      const response = await api.put(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Get user profile by username
  getUserProfile: async (username) => {
    try {
      const response = await api.get(`/api/users/${username}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update user profile
  updateUserProfile: async (username, profileData) => {
    try {
      const response = await api.put(`/api/users/${username}`, profileData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get user's social links
  getUserSocialLinks: async (username) => {
    try {
      const response = await api.get(`/api/users/${username}/social-links`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update user's social links
  updateUserSocialLinks: async (username, socialLinks) => {
    try {
      const response = await api.put(`/api/users/${username}/social-links`, { socialLinks });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get user's payment links (public)
  getPaymentLinks: async (username) => {
    try {
      const response = await publicApi.get(`/api/user/${username}/payment-links`);
      return response.data.paymentLinks; // Returns array of payment links
    } catch (error) {
      console.error('Error fetching payment links:', error);
      return [];
    }
  },

  // Update user's payment links (authenticated)
  updatePaymentLinks: async (username, paymentLinks) => {
    try {
      // Ensure paymentLinks is in the correct array format
      const links = Array.isArray(paymentLinks) ? paymentLinks : paymentLinks.paymentLinks;
      
      // Validate and normalize each link
      const validLinks = links
        .filter(link => link.platform && link.username)
        .map(link => ({
          platform: link.platform.toLowerCase().replace(/\s+/g, ''),
          username: link.username
        }));

      const response = await api.put(`/api/user/${username}/payment-links`, {
        paymentLinks: validLinks
      });
      return response.data.paymentLinks;
    } catch (error) {
      console.error('Error updating payment links:', error);
      throw error;
    }
  },

  // Get user's extra links
  getExtraLinks: async (username) => {
    try {
      const response = await api.get(`/api/user/${username}/extra-links`);
      // Return the extra_links array or an empty array if it doesn't exist
      return response.data.extra_links || [];
    } catch (error) {
      console.error('Error fetching extra links:', error);
      return [];
    }
  },

  // Update user's extra links
  updateExtraLinks: async (username, links) => {
    try {
      // Wrap the links in the extra_links property as expected by the API
      const response = await api.put(`/api/user/${username}/extra-links`, { 
        extra_links: links.map(link => ({
          title: link.title.trim(),
          url: link.url.trim()
        }))
      });
      // Return the extra_links array from the response or the original links if not present
      return response.data.extra_links || links;
    } catch (error) {
      console.error('Error updating extra links:', error);
      throw error;
    }
  },

  // Get user's modules configuration
  getModules: async () => {
    try {
      const response = await api.get('/api/user/modules');
      return response.data.modules;
    } catch (error) {
      console.error('Error fetching modules:', error);
      return {
        resume: false,
        payment: false,
        extraLinks: false
      };
    }
  },

  // Update user's modules configuration
  updateModules: async (modules) => {
    try {
      const response = await api.put('/api/user/modules', { modules });
      return response.data.modules;
    } catch (error) {
      console.error('Error updating modules:', error);
      throw error;
    }
  }
};

// Connection related API calls
export const connectionApi = {
  // Send connection request
  sendRequest: async (userId) => {
    try {
      const response = await api.post('/api/connections/request', { user_id: userId });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Accept connection request
  acceptRequest: async (connectionId) => {
    try {
      const response = await api.post(`/api/connections/accept/${connectionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Decline connection request
  declineRequest: async (connectionId) => {
    try {
      const response = await api.post(`/api/connections/decline/${connectionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Remove connection
  removeConnection: async (connectionId) => {
    try {
      const response = await api.delete(`/api/connections/${connectionId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get user's connections
  getConnections: async () => {
    try {
      const response = await api.get('/api/connections', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get all connections in the system
  getAllConnections: async () => {
    try {
      const response = await api.get('/api/connections/all', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get all pending connection requests (both inbound and outbound)
  getPendingRequests: async () => {
    try {
      const [inboundResponse, outboundResponse] = await Promise.all([
        api.get('/api/connections/pending/inbound'),
        api.get('/api/connections/pending/outbound')
      ]);
      
      return {
        data: {
          inbound: inboundResponse.data.data || [],
          outbound: outboundResponse.data.data || []
        }
      };
    } catch (error) {
      throw error;
    }
  },

  // Get all public users
  getAllUsers: async () => {
    try {
      const response = await api.get('/api/users/public', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      console.log('response', response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

// Resume related API calls
export const resumeApi = {
  // Get user's resume (public)
  getResume: async (username) => {
    try {
      const response = await publicApi.get(`/api/profile/${username}`);
      return response.data;
    } catch (error) {
      // If it's a 404, return null instead of throwing
      if (error.response?.status === 404) {
        return { data: null };
      }
      throw error;
    }
  },

  // Save user's resume (protected)
  saveResume: async (username, resumeData) => {
    try {
      const response = await api.put(`/api/profile/${username}`, resumeData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

// Error handler helper
export const handleApiError = (error) => {
  if (error.response) {
    // Server responded with error
    if (error.response.data.message) {
      return error.response.data.message;
    }
    if (error.response.data.errors) {
      // Return first validation error
      const firstError = Object.values(error.response.data.errors)[0];
      return Array.isArray(firstError) ? firstError[0] : firstError;
    }
    return `Error: ${error.response.status} ${error.response.statusText}`;
  }
  if (error.request) {
    // Request made but no response
    return 'No response from server. Please check your connection.';
  }
  // Error setting up request
  return error.message || 'An unexpected error occurred';
};

// Auth token helper
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Test the API connection
export const testApiConnection = async () => {
  try {
    const response = await authApi.test();
    return {
      success: true,
      message: 'API connection successful',
      data: response.data
    };
  } catch (error) {
    return {
      success: false,
      message: 'API connection failed',
      error: handleApiError(error)
    };
  }
};

export default api;
