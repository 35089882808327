import React, { useState, useEffect } from 'react';
import { connectionApi } from '../services/api';

const PendingConnections = ({ initialInbound = [], initialOutbound = [], onRequestsUpdate }) => {
  const [inboundRequests, setInboundRequests] = useState(initialInbound);
  const [outboundRequests, setOutboundRequests] = useState(initialOutbound);
  const [error, setError] = useState(null);

  const handleAccept = async (connectionId) => {
    try {
      await connectionApi.acceptRequest(connectionId);
      const newInbound = inboundRequests.filter(req => req.id !== connectionId);
      setInboundRequests(newInbound);
      onRequestsUpdate?.(newInbound, outboundRequests);
    } catch (err) {
      setError('Failed to accept connection request');
    }
  };

  const handleDecline = async (connectionId) => {
    try {
      await connectionApi.declineRequest(connectionId);
      const newInbound = inboundRequests.filter(req => req.id !== connectionId);
      setInboundRequests(newInbound);
      onRequestsUpdate?.(newInbound, outboundRequests);
    } catch (err) {
      setError('Failed to decline connection request');
    }
  };

  const handleCancel = async (connectionId) => {
    try {
      await connectionApi.declineRequest(connectionId);
      const newOutbound = outboundRequests.filter(req => req.id !== connectionId);
      setOutboundRequests(newOutbound);
      onRequestsUpdate?.(inboundRequests, newOutbound);
    } catch (err) {
      setError('Failed to cancel connection request');
    }
  };

  const getInitials = (name) => {
    if (!name) return '??';
    return name.split(' ').slice(0, 2).map(n => n[0]).join('').toUpperCase();
  };

  const RequestCard = ({ request, type }) => {
    const user = type === 'inbound' ? request.sender : request.receiver;
    const initials = getInitials(user?.name);
    
    return (
      <div 
        key={request.id} 
        className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex flex-col sm:flex-row sm:items-center justify-between border border-gray-200 dark:border-gray-700 gap-4"
      >
        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300 font-semibold text-sm">
            {initials}
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 dark:text-gray-100">
              {user?.name}
            </h3>
            {user?.title && (
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {user?.title}
              </p>
            )}
            <p className="text-xs text-gray-500 dark:text-gray-500">
              {new Date(request.created_at).toLocaleDateString()}
            </p>
          </div>
        </div>
        
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 w-full sm:w-auto">
          {type === 'inbound' ? (
            <>
              <button
                onClick={() => handleAccept(request.id)}
                className="w-full sm:w-auto bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                Accept
              </button>
              <button
                onClick={() => handleDecline(request.id)}
                className="w-full sm:w-auto bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Decline
              </button>
            </>
          ) : (
            <button
              onClick={() => handleCancel(request.id)}
              className="w-full sm:w-auto bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-700 transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Cancel Request
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8">
      {error && (
        <div className="bg-red-100 dark:bg-red-900/50 border border-red-400 dark:border-red-800 text-red-700 dark:text-red-400 px-4 py-3 rounded mb-4 relative">
          <span className="block sm:inline">{error}</span>
          <button 
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setError(null)}
          >
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
            </svg>
          </button>
        </div>
      )}

      {/* Inbound Requests Section */}
      <div>
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
          Incoming Connection Requests
        </h2>
        {inboundRequests.length === 0 ? (
          <div className="text-center py-4 text-gray-600 dark:text-gray-400">
            <p>No incoming connection requests</p>
          </div>
        ) : (
          <div className="space-y-4">
            {inboundRequests.map((request) => (
              <RequestCard key={request.id} request={request} type="inbound" />
            ))}
          </div>
        )}
      </div>

      {/* Outbound Requests Section */}
      <div>
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">
          Sent Connection Requests
        </h2>
        {outboundRequests.length === 0 ? (
          <div className="text-center py-4 text-gray-600 dark:text-gray-400">
            <p>No sent connection requests</p>
          </div>
        ) : (
          <div className="space-y-4">
            {outboundRequests.map((request) => (
              <RequestCard key={request.id} request={request} type="outbound" />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingConnections;
