import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { FaSave, FaLink } from 'react-icons/fa';
import { authApi } from '../../services/api';

const ExtraLinks = () => {
  const { user } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [extraLinks, setExtraLinks] = useState([]);

  useEffect(() => {
    const fetchExtraLinks = async () => {
      if (!user?.username) {
        setIsLoading(false);
        return;
      }

      try {
        const links = await authApi.getExtraLinks(user.username);
        setExtraLinks(Array.isArray(links) ? links : []);
      } catch (error) {
        console.error('Error fetching extra links:', error);
        setExtraLinks([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchExtraLinks();
  }, [user]);

  const handleAddLink = () => {
    setExtraLinks([...extraLinks, { title: '', url: '' }]);
  };

  const handleRemoveLink = (index) => {
    const newLinks = [...extraLinks];
    newLinks.splice(index, 1);
    setExtraLinks(newLinks);
  };

  const handleUpdateLink = (index, field, value) => {
    const newLinks = [...extraLinks];
    newLinks[index] = { ...newLinks[index], [field]: value };
    setExtraLinks(newLinks);
  };

  const validateLinks = () => {
    const errors = [];
    extraLinks.forEach((link, index) => {
      if (!link.title) {
        errors.push(`Link ${index + 1}: Title is required`);
      }
      if (!link.url) {
        errors.push(`Link ${index + 1}: URL is required`);
      }
      if (link.url && !isValidUrl(link.url)) {
        errors.push(`Link ${index + 1}: Invalid URL format`);
      }
    });
    return errors;
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleSave = async () => {
    if (!user?.username) {
      setSaveStatus('Please log in to save your links');
      return;
    }

    const validationErrors = validateLinks();
    if (validationErrors.length > 0) {
      setSaveStatus(`Validation errors: ${validationErrors[0]}`);
      return;
    }

    setIsSaving(true);
    setSaveStatus('Saving...');

    try {
      const validLinks = extraLinks
        .filter(link => link.title && link.url)
        .map(link => ({
          title: link.title.trim(),
          url: link.url.trim()
        }));

      const updatedLinks = await authApi.updateExtraLinks(user.username, validLinks);
      setExtraLinks(Array.isArray(updatedLinks) ? updatedLinks : validLinks);
      
      setSaveStatus('Links saved successfully!');

      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } catch (error) {
      console.error('Error saving links:', error);
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error ||
                          'Error saving links. Please try again.';
      setSaveStatus(errorMessage);
      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-[50vh] bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-6 max-w-3xl mx-auto">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-3 mb-6">
        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
          Extra Links
        </h2>
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`
            flex items-center justify-center px-4 py-2.5 sm:px-6
            rounded-lg text-white font-medium shadow-lg
            transition-all duration-200 ease-in-out
            min-h-[44px] sm:min-h-[unset]
            ${isSaving 
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 active:from-blue-700 active:to-blue-800'
            }
          `}
        >
          <FaSave className={`w-5 h-5 mr-2 ${isSaving ? 'animate-spin' : ''}`} />
          {isSaving ? 'Saving...' : 'Save Links'}
        </button>
      </div>

      {/* Links List */}
      <div className="space-y-4">
        {(extraLinks || []).map((link, index) => (
          <div 
            key={index} 
            className="flex flex-col gap-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700"
          >
            <div className="flex-grow space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5">
                  Title
                </label>
                <input
                  type="text"
                  value={link.title}
                  onChange={(e) => handleUpdateLink(index, 'title', e.target.value)}
                  className="w-full px-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                           focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                           dark:bg-gray-700 dark:border-gray-600 dark:text-white 
                           text-base min-h-[44px]"
                  placeholder="e.g., My YouTube Video, Portfolio, Blog Post"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1.5">
                  URL
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                    <FaLink className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                  </div>
                  <input
                    type="url"
                    value={link.url}
                    onChange={(e) => handleUpdateLink(index, 'url', e.target.value)}
                    className="w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg shadow-sm 
                             focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                             dark:bg-gray-700 dark:border-gray-600 dark:text-white 
                             text-base min-h-[44px]"
                    placeholder="https://example.com"
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => handleRemoveLink(index)}
                className="flex items-center justify-center px-4 py-2 
                         text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 
                         border border-red-200 dark:border-red-700 rounded-lg 
                         hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors
                         min-h-[44px] w-full sm:w-auto"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                <span className="ml-2">Remove Link</span>
              </button>
            </div>
          </div>
        ))}

        {/* Add Link Button */}
        <button
          onClick={handleAddLink}
          className="w-full py-3 border-2 border-dashed border-gray-300 dark:border-gray-600 
                   rounded-lg text-gray-600 dark:text-gray-400 
                   hover:border-blue-500 hover:text-blue-500 
                   dark:hover:border-blue-400 dark:hover:text-blue-400 
                   transition-colors duration-200 text-base
                   min-h-[44px]"
        >
          + Add Link
        </button>
      </div>

      {/* Toast Notification */}
      {saveStatus && (
        <div className="fixed bottom-0 inset-x-0 p-4 sm:p-0 sm:bottom-4 sm:right-4 sm:left-auto z-50">
          <div 
            className={`
              px-4 py-3 rounded-lg shadow-lg max-w-md mx-auto sm:mx-0
              transform transition-all duration-300 ease-in-out
              translate-y-0 opacity-100
              ${saveStatus.includes('Error') || saveStatus.includes('Validation') 
                ? 'bg-red-500 text-white' 
                : saveStatus === 'Saving...' 
                  ? 'bg-yellow-500 text-white'
                  : 'bg-green-500 text-white'
              }
            `}
          >
            <div className="flex items-center space-x-2">
              <svg 
                className="w-5 h-5 flex-shrink-0" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                {saveStatus.includes('Error') || saveStatus.includes('Validation') ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                ) : saveStatus === 'Saving...' ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                )}
              </svg>
              <span className="font-medium text-sm sm:text-base">{saveStatus}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraLinks;
