import React, { useState, useEffect } from 'react';
import PendingConnections from '../components/PendingConnections';
import { connectionApi } from '../services/api';

const Connections = () => {
  const [activeTab, setActiveTab] = useState('connections');
  const [connections, setConnections] = useState([]);
  const [inboundRequests, setInboundRequests] = useState([]);
  const [outboundRequests, setOutboundRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const tabs = [
    { id: 'connections', label: 'My Connections' },
    { id: 'pending', label: 'Pending Requests' }
  ];

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch both connections and pending requests in parallel
        const [connectionsResponse, pendingResponse] = await Promise.all([
          connectionApi.getConnections(),
          connectionApi.getPendingRequests()
        ]);

        // Set connections
        const connectionData = connectionsResponse.connections || [];
        setConnections(connectionData);

        // Set pending requests
        const { inbound = [], outbound = [] } = pendingResponse.data || {};
        setInboundRequests(inbound);
        setOutboundRequests(outbound);
      } catch (err) {
        setError('Failed to load data. Please try again later.');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const handleRemoveConnection = async (connectionId) => {
    try {
      await connectionApi.removeConnection(connectionId);
      setConnections(connections.filter(conn => conn.id !== connectionId));
    } catch (err) {
      console.error('Error removing connection:', err);
    }
  };

  // Helper function to get the connected user's data
  const getConnectedUser = (connection) => {
    // Get the current user's ID from localStorage or context if available
    const currentUserId = parseInt(localStorage.getItem('user_id'));
    
    // If the current user is the requester, return the requested user's data
    // Otherwise, return the requester's data
    return connection.requester_id === currentUserId 
      ? connection.requested 
      : connection.requester;
  };

  const getInitials = (name) => {
    if (!name) return '??';
    return name.split(' ').slice(0, 2).map(n => n[0]).join('').toUpperCase();
  };

  const hasPendingRequests = inboundRequests.length > 0 || outboundRequests.length > 0;

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md">
        {/* Tab Navigation */}
        <div className="border-b border-gray-200 dark:border-gray-700">
          <nav className="flex space-x-4 sm:space-x-8 px-4" aria-label="Connections navigation">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 relative ${
                  activeTab === tab.id
                    ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                    : 'border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-600'
                }`}
              >
                {tab.label}
                {tab.id === 'pending' && hasPendingRequests && (
                  <span className="absolute top-3 -right-1 h-2 w-2 bg-red-500 rounded-full"></span>
                )}
              </button>
            ))}
          </nav>
        </div>

        {/* Tab Content */}
        <div className="p-2 sm:p-4">
          {activeTab === 'pending' && (
            <PendingConnections 
              initialInbound={inboundRequests}
              initialOutbound={outboundRequests}
              onRequestsUpdate={(inbound, outbound) => {
                setInboundRequests(inbound);
                setOutboundRequests(outbound);
              }}
            />
          )}
          {activeTab === 'connections' && (
            <div>
              {loading && (
                <div className="text-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
                </div>
              )}
              
              {error && (
                <div className="text-center py-8 text-red-600 dark:text-red-400">
                  {error}
                </div>
              )}

              {!loading && !error && connections.length === 0 && (
                <div className="text-center py-8 text-gray-600 dark:text-gray-400">
                  <p>You don't have any connections yet.</p>
                </div>
              )}

              {!loading && !error && connections.length > 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {connections.map(connection => {
                    const connectedUser = getConnectedUser(connection);
                    const initials = getInitials(connectedUser.name);
                    return (
                      <div 
                        key={connection.id}
                        className="bg-white dark:bg-gray-700 rounded-lg shadow p-4 flex items-center justify-between"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="h-12 w-12 rounded-full flex items-center justify-center bg-blue-100 dark:bg-blue-900 text-blue-600 dark:text-blue-300 font-semibold text-sm">
                            {initials}
                          </div>
                          <div>
                            <h3 className="font-medium text-gray-900 dark:text-white">
                              {connectedUser.name}
                            </h3>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                              @{connectedUser.username}
                            </p>
                          </div>
                        </div>
                        <button
                          onClick={() => handleRemoveConnection(connection.id)}
                          className="text-gray-400 hover:text-red-500 dark:text-gray-500 dark:hover:text-red-400 transition-colors duration-200"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Connections;
