import { getFontSize } from '../utils/positioning';

const NodeRenderer = (node, ctx, globalScale, colors, isDarkMode, expandedNodes) => {
    const size = node.size;
    ctx.save();
    
    // Draw node with gradient
    ctx.translate(node.x, node.y);

    // Determine if node is expanded
    const isExpanded = expandedNodes.has(node.id);
    const colorKey = isExpanded ? 'expanded' : node.colorKey;
    
    // Add larger invisible hit area
    ctx.beginPath();
    ctx.arc(0, 0, size * 1.5, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.fill();
    
    // Draw main node circle
    ctx.beginPath();
    ctx.arc(0, 0, size, 0, 2 * Math.PI);
    ctx.fillStyle = colors[colorKey].gradient(ctx);
    ctx.fill();
    
    // Add glow effect
    ctx.shadowColor = colors[colorKey].primary;
    ctx.shadowBlur = isExpanded ? 20 : (node.colorKey === 'secondary' ? 8 : 15);
    ctx.strokeStyle = colors[colorKey].primary;
    ctx.lineWidth = isExpanded ? 3 : (node.colorKey === 'secondary' ? 1 : 2);
    ctx.stroke();

    // Add expanded indicator
    if (isExpanded) {
        // Inner ring
        ctx.beginPath();
        ctx.arc(0, 0, size + 4, 0, 2 * Math.PI);
        ctx.strokeStyle = colors[colorKey].primary;
        ctx.lineWidth = 2;
        ctx.setLineDash([2, 2]);
        ctx.stroke();
        ctx.setLineDash([]);

        // Outer ring with pulse effect
        const time = Date.now() * 0.003; // Slow down the pulse
        const pulseSize = Math.sin(time) * 2 + 8; // Pulse between 6 and 10
        ctx.beginPath();
        ctx.arc(0, 0, size + pulseSize, 0, 2 * Math.PI);
        ctx.strokeStyle = colors[colorKey].primary;
        ctx.lineWidth = 1;
        ctx.globalAlpha = 0.5 - Math.sin(time) * 0.2; // Fade in/out with pulse
        ctx.stroke();
        ctx.globalAlpha = 1;
    }

    // Draw connection count
    if (node.approvedConnections !== undefined && node.name !== 'You') {
        const count = node.approvedConnections;
        ctx.font = 'bold 4px Sans-Serif';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = isDarkMode ? '#E5E7EB' : '#374151';
        ctx.shadowBlur = 0;
        
        // Draw a small background circle for the count
        ctx.beginPath();
        ctx.arc(size * 0.7, -size * 0.7, size * 0.45, 0, 2 * Math.PI);
        ctx.fillStyle = isDarkMode ? 'rgba(31, 41, 55, 0.9)' : 'rgba(255, 255, 255, 0.9)';
        ctx.fill();
        ctx.strokeStyle = colors[colorKey].primary;
        ctx.lineWidth = 0.5;
        ctx.stroke();
        
        // Draw the count
        ctx.fillStyle = isDarkMode ? '#E5E7EB' : '#374151';
        ctx.fillText(count.toString(), size * 0.7, -size * 0.7);
    }
    
    // Draw label with fixed small font size
    const label = node.name;
    const fontSize = 3; // Fixed small size
    ctx.font = `${fontSize}px Sans-Serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = isDarkMode ? '#E5E7EB' : '#374151';
    ctx.shadowBlur = 0;
    ctx.fillText(label, 0, size + fontSize);
    
    ctx.restore();
};

export default NodeRenderer;
