import { useState, useEffect, useCallback } from 'react';
import { connectionApi } from '../../../services/api';
import { calculateNodePosition, getNodeSize } from '../utils/positioning';

export const useNetworkData = (currentUserId, user, dimensions) => {
    const [graphData, setGraphData] = useState({ nodes: [], links: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [connections, setConnections] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [visibleLinks, setVisibleLinks] = useState(new Set());
    const [expandedNodes, setExpandedNodes] = useState(new Set());

    const updateGraphData = useCallback((currentConnections, users = []) => {
        try {
            const nodes = [];
            const links = [];
            const newVisibleLinks = new Set();
            const usedPositions = [];

            // Calculate maxRadius based on screen size
            const baseRadius = Math.min(dimensions.width, dimensions.height) * 0.35;
            const nodeCount = users.length;
            const maxRadius = baseRadius * (1 + Math.log10(nodeCount + 1) * 0.2);
            const minDistance = maxRadius * (0.3 / Math.log10(nodeCount + 2));

            // Add center node only if user is logged in
            if (currentUserId && user) {
                const centerPos = { x: 0, y: 0 };
                usedPositions.push(centerPos);
                nodes.push({
                    id: currentUserId,
                    name: 'You',
                    colorKey: 'you',
                    size: getNodeSize('you', dimensions),
                    username: user.username,
                    x: centerPos.x,
                    y: centerPos.y,
                    fx: centerPos.x,
                    fy: centerPos.y
                });
            }

            // Track direct connections if user is logged in
            const directConnectionIds = new Set();
            if (currentUserId && user) {
                currentConnections.forEach(conn => {
                    if (conn.users) {
                        const [user1, user2] = conn.users;
                        if (user1.id === currentUserId) {
                            directConnectionIds.add(user2.id);
                        } else if (user2.id === currentUserId) {
                            directConnectionIds.add(user1.id);
                        }
                    }
                });
            }

            // For logged in users: Add direct connection nodes first (inner circle)
            // For guests: Add all nodes in a circular layout
            const nodesToAdd = currentUserId && user 
                ? users.filter(u => u.id !== currentUserId)
                : users;

            nodesToAdd.forEach((user, index) => {
                const isDirectConnection = directConnectionIds.has(user.id);
                const radius = currentUserId 
                    ? (isDirectConnection ? maxRadius * 0.5 : maxRadius)
                    : maxRadius * 0.7; // For guests, use a medium radius for all nodes

                const position = calculateNodePosition(
                    user.id + index,
                    usedPositions,
                    dimensions,
                    radius,
                    minDistance
                );
                usedPositions.push(position);

                nodes.push({
                    id: user.id,
                    name: user.name || 'Unknown User',
                    colorKey: isDirectConnection ? 'connected' : 'secondary',
                    size: getNodeSize(isDirectConnection ? 'direct' : 'secondary', dimensions),
                    username: user.username,
                    x: position.x,
                    y: position.y,
                    fx: position.x,
                    fy: position.y
                });
            });

            // Add connections
            currentConnections.forEach(conn => {
                if (!conn.users) return;
                
                const [user1, user2] = conn.users;
                const sourceId = user1.id;
                const targetId = user2.id;

                const isDirectConnection = currentUserId && (sourceId === currentUserId || targetId === currentUserId);
                const linkId = `${Math.min(sourceId, targetId)}-${Math.max(sourceId, targetId)}`;

                links.push({
                    id: linkId,
                    source: sourceId,
                    target: targetId,
                    isDirectConnection
                });

                // For logged in users: Show only direct connections by default
                // For guests: Show all connections
                if (!currentUserId || isDirectConnection) {
                    newVisibleLinks.add(linkId);
                }
            });

            setVisibleLinks(newVisibleLinks);
            setGraphData({ nodes, links });

        } catch (err) {
            console.error('Error updating graph data:', err);
            setError('Error updating network visualization');
        }
    }, [currentUserId, user, dimensions]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await connectionApi.getAllConnections();
                const fetchedConnections = response?.connections || [];
                
                const uniqueUsers = new Set();
                fetchedConnections.forEach(conn => {
                    if (conn.users) {
                        conn.users.forEach(user => {
                            if (user && user.id) {
                                uniqueUsers.add(JSON.stringify(user));
                            }
                        });
                    }
                });
                const users = Array.from(uniqueUsers).map(userStr => JSON.parse(userStr));

                setConnections(fetchedConnections);
                setAllUsers(users);
                setExpandedNodes(new Set());
                updateGraphData(fetchedConnections, users);
            } catch (err) {
                console.error('[NetworkMap] Error fetching data:', err);
                setError('Failed to load network data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentUserId, updateGraphData]);

    return {
        graphData,
        loading,
        error,
        connections,
        allUsers,
        visibleLinks,
        expandedNodes,
        setExpandedNodes,
        setVisibleLinks,
        updateGraphData
    };
};

export default useNetworkData;
