import React from 'react';

const Controls = ({ 
    isLegendOpen, 
    setIsLegendOpen,
    colors,
    user
}) => {
    return (
        <div className="absolute top-16 right-4 z-10">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 w-64">
                <div 
                    className="p-3 flex items-center justify-between cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors"
                    onClick={() => setIsLegendOpen(!isLegendOpen)}
                >
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Legend</span>
                    <svg 
                        className={`w-5 h-5 text-gray-500 transition-transform ${isLegendOpen ? 'transform rotate-180' : ''}`}
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                </div>

                {isLegendOpen && (
                    <div className="p-3 space-y-3 border-t border-gray-200 dark:border-gray-700">
                        {!user ? (
                            <>
                                <div className="space-y-2">
                                    <p className="text-xs font-medium text-blue-600 dark:text-blue-400">Preview Mode</p>
                                    <p className="text-sm text-gray-600 dark:text-gray-400">
                                        Register or login to unlock the full experience:
                                    </p>
                                    <ul className="text-sm text-gray-600 dark:text-gray-400 list-disc pl-4 space-y-1">
                                        <li>See your direct connections</li>
                                        <li>Explore connection networks</li>
                                        <li>View detailed profiles</li>
                                        <li>Build your own network</li>
                                    </ul>
                                </div>

                                <div className="space-y-2">
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Map Usage</p>
                                    <div className="space-y-1">
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Click a node to see its connections</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Double-click for profile preview</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Drag nodes to reposition</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Scroll to zoom in/out</p>
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Node Types</p>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.connected.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">Network Members</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.expanded.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">Selected Node</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="space-y-2">
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Nodes</p>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.you.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">You</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.connected.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">Direct Connections</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.secondary.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">Secondary Connections</span>
                                    </div>
                                    <div className="flex items-center space-x-2">
                                        <span className="w-3 h-3 rounded-full" style={{ background: colors.expanded.primary }}></span>
                                        <span className="text-sm text-gray-600 dark:text-gray-400">Expanded Node</span>
                                    </div>
                                </div>
                                
                                <div className="space-y-2">
                                    <p className="text-xs font-medium text-gray-500 dark:text-gray-400">Interactions</p>
                                    <div className="space-y-1">
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Click direct connection to toggle their connections</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Double click direct connection to view profile</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Click any other node to view profile</p>
                                        <p className="text-sm text-gray-600 dark:text-gray-400">• Drag nodes to reposition</p>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Controls;
