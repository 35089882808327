import React from 'react';
import { motion } from 'framer-motion';
import { HiCash } from 'react-icons/hi';
import { FaDollarSign } from 'react-icons/fa';

const ProfilePaymentLinks = ({ paymentLinks }) => {
  if (!paymentLinks?.length) return null;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardHover = {
    scale: 1.02,
    transition: { duration: 0.2 }
  };

  const getPaymentIcon = () => {
    return { icon: FaDollarSign, color: 'text-green-500' };
  };

  const getPlatformUrl = (platform, username) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const platformMap = {
      cashapp: {
        web: `https://cash.app/$${username}`,
        mobile: `https://cash.app/$${username}`
      },
      venmo: {
        web: `https://venmo.com/${username}`,
        mobile: `venmo://users/${username}`
      },
      paypal: {
        web: `https://paypal.me/${username}`,
        mobile: `https://paypal.me/${username}`
      },
      zelle: {
        web: `#`, // Zelle doesn't support direct links
        mobile: `#`
      }
    };

    const normalizedPlatform = platform.toLowerCase().replace(/\s+/g, '');
    const platformUrls = platformMap[normalizedPlatform] || {
      web: `https://${platform}.com/${username}`,
      mobile: `https://${platform}.com/${username}`
    };

    return isMobile ? platformUrls.mobile : platformUrls.web;
  };

  return (
    <motion.div 
      variants={fadeInUp}
      className="mt-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden"
    >
      <div className="p-6">
        <motion.h2 
          variants={fadeInUp}
          className="text-xl font-bold text-gray-900 dark:text-white mb-6 flex items-center gap-2"
        >
          <HiCash className="w-6 h-6 text-blue-500" />
          Payment Links
        </motion.h2>
        
        <motion.div 
          variants={staggerContainer}
          className="grid gap-4"
        >
          {paymentLinks.map((link, index) => {
            const { icon: Icon, color } = getPaymentIcon();
            const platformUrl = getPlatformUrl(link.platform, link.username);
            
            // Don't make Zelle entries clickable since they don't support direct links
            const isZelle = link.platform.toLowerCase() === 'zelle';
            const Component = isZelle ? motion.div : motion.a;
            const props = isZelle ? {} : {
              href: platformUrl,
              target: "_blank",
              rel: "noopener noreferrer"
            };

            return (
              <Component
                key={index}
                variants={fadeInUp}
                whileHover={cardHover}
                {...props}
                className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md"
              >
                <div className={`w-12 h-12 rounded-lg bg-gray-100 dark:bg-gray-600 flex items-center justify-center ${color}`}>
                  <Icon className="w-6 h-6" />
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white capitalize">
                    {link.platform}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    @{link.username}
                  </p>
                </div>
              </Component>
            );
          })}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ProfilePaymentLinks;
