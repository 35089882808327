import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { resumeApi } from '../../services/api';
import { FaSave } from 'react-icons/fa';
import WorkHistory from './WorkHistory';
import Skills from './Skills';
import Projects from './Projects';
import Education from './Education';

const Resume = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('work');
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Initialize all sections with empty arrays
  const [workHistory, setWorkHistory] = useState([]);
  const [education, setEducation] = useState([]);
  const [projects, setProjects] = useState([]);
  const [skills, setSkills] = useState([]);

  const tabs = [
    { id: 'work', label: 'Experience' },
    { id: 'education', label: 'Education' },
    { id: 'projects', label: 'Projects' },
    { id: 'skills', label: 'Skills' }
  ];

  // Fetch resume data when component mounts
  useEffect(() => {
    const fetchResumeData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await resumeApi.getResume(user.username);
        // Handle case where data is null (new user with no resume)
        if (!response.data) {
          setWorkHistory([]);
          setEducation([]);
          setProjects([]);
          setSkills([]);
          setIsLoading(false);
          return;
        }

        const data = response.data;

        // Transform the API response data to match component state structure
        setWorkHistory(data.workHistory?.map((work, index) => ({
          id: index,
          company: work.company,
          companyLink: work.companyLink,
          position: work.position,
          startDate: work.startDate,
          endDate: work.endDate,
          responsibilities: work.responsibilities
        })) || []);

        setEducation(data.education?.map((edu, index) => ({
          id: index,
          school: edu.institution,
          degree: edu.degree,
          fieldOfStudy: edu.fieldOfStudy || '',
          startDate: edu.startDate || '',
          endDate: edu.graduationDate || '',
          description: edu.description || ''
        })) || []);

        setProjects(data.projects?.map((proj, index) => ({
          id: index,
          title: proj.name,
          description: proj.description,
          technologies: proj.technologies || '',
          link: proj.link || ''
        })) || []);

        setSkills(data.skills?.map((skill, index) => ({
          id: index,
          name: skill.name || skill, // Handle both new and old format
          category: skill.category || '',
          rating: skill.proficiencyLevel || 0
        })) || []);
      } catch (error) {
        console.error('Error fetching resume:', error);
        // Initialize with empty arrays instead of showing error
        setWorkHistory([]);
        setEducation([]);
        setProjects([]);
        setSkills([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResumeData();
  }, [user]);

  // Only validate entries that have some data
  const validateWorkEntry = (entry) => {
    const hasData = entry.company?.trim() || entry.position?.trim() || entry.startDate;
    if (!hasData) return []; // Skip validation if entry is empty

    const errors = [];
    if (hasData) {
      if (!entry.company?.trim()) errors.push('Company is required');
      if (!entry.position?.trim()) errors.push('Position is required');
      if (!entry.startDate) errors.push('Start date is required');
      if (entry.endDate && new Date(entry.endDate) < new Date(entry.startDate)) {
        errors.push('End date must be after start date');
      }
      // Validate companyLink if provided
      if (entry.companyLink && !entry.companyLink.match(/^https?:\/\/.+/)) {
        errors.push('Company link must be a valid URL starting with http:// or https://');
      }
    }
    return errors;
  };

  const validateEducationEntry = (entry) => {
    const hasData = entry.school?.trim() || entry.degree?.trim() || entry.endDate;
    if (!hasData) return []; // Skip validation if entry is empty

    const errors = [];
    if (hasData) {
      if (!entry.school?.trim()) errors.push('Institution is required');
      if (!entry.degree?.trim()) errors.push('Degree is required');
      if (!entry.endDate) errors.push('Graduation date is required');
    }
    return errors;
  };

  const validateProjectEntry = (entry) => {
    const hasData = entry.title?.trim() || entry.description?.trim();
    if (!hasData) return []; // Skip validation if entry is empty

    const errors = [];
    if (hasData) {
      if (!entry.title?.trim()) errors.push('Project name is required');
      if (!entry.description?.trim()) errors.push('Description is required');
    }
    return errors;
  };

  // Validate only entries that have data
  const validateData = () => {
    const errors = [];

    // Validate work history entries that have data
    workHistory.forEach((entry, index) => {
      const entryErrors = validateWorkEntry(entry);
      if (entryErrors.length > 0) {
        errors.push(`Work History Entry ${index + 1}: ${entryErrors.join(', ')}`);
      }
    });

    // Validate education entries that have data
    education.forEach((entry, index) => {
      const entryErrors = validateEducationEntry(entry);
      if (entryErrors.length > 0) {
        errors.push(`Education Entry ${index + 1}: ${entryErrors.join(', ')}`);
      }
    });

    // Validate project entries that have data
    projects.forEach((entry, index) => {
      const entryErrors = validateProjectEntry(entry);
      if (entryErrors.length > 0) {
        errors.push(`Project Entry ${index + 1}: ${entryErrors.join(', ')}`);
      }
    });

    return errors;
  };

  const handleSave = async () => {
    if (!user) {
      setSaveStatus('Please log in to save your resume');
      return;
    }

    // Validate data before sending
    const validationErrors = validateData();
    if (validationErrors.length > 0) {
      setSaveStatus(`Validation errors: ${validationErrors[0]}`); // Show first error
      console.error('Validation errors:', validationErrors); // Log all errors
      return;
    }

    setIsSaving(true);
    setSaveStatus('Saving...');

    try {
      // Filter out empty entries and transform the data
      const resumeData = {
        workHistory: workHistory
          .filter(work => work.company?.trim() || work.position?.trim() || work.startDate)
          .map(({ id, isNew, ...work }) => ({
            company: work.company?.trim() || '',
            companyLink: work.companyLink?.trim() || '',
            position: work.position?.trim() || '',
            startDate: work.startDate || null,
            endDate: work.endDate || null,
            responsibilities: work.responsibilities?.trim() || ''
          })),
        education: education
          .filter(edu => edu.school?.trim() || edu.degree?.trim() || edu.endDate)
          .map(({ id, isNew, ...edu }) => ({
            institution: edu.school?.trim() || '',
            degree: edu.degree?.trim() || '',
            fieldOfStudy: edu.fieldOfStudy?.trim() || '',
            startDate: edu.startDate || null,
            graduationDate: edu.endDate || null,
            description: edu.description?.trim() || ''
          })),
        projects: projects
          .filter(proj => proj.title?.trim() || proj.description?.trim())
          .map(({ id, isNew, ...proj }) => ({
            name: proj.title?.trim() || '',
            description: proj.description?.trim() || '',
            technologies: proj.technologies?.trim() || '',
            link: proj.link?.trim() || ''
          })),
        skills: skills
          .filter(skill => skill.name?.trim())
          .map(({ id, ...skill }) => ({
            name: skill.name.trim(),
            category: skill.category?.trim() || '',
            proficiencyLevel: skill.rating || 0
          }))
      };

      await resumeApi.saveResume(user.username, resumeData);
      setSaveStatus('Resume saved successfully!');

      // Clear success message after 5 seconds
      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } catch (error) {
      console.error('Error saving resume:', error);
      const errorMessage = error.response?.data?.message || 
                          error.response?.data?.error ||
                          'Error saving resume. Please try again.';
      setSaveStatus(errorMessage);
      // Clear error message after 5 seconds
      setTimeout(() => {
        setSaveStatus('');
      }, 5000);
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-6xl mx-auto px-4 py-6 sm:py-8">
        <div className="flex flex-col space-y-4">
          {/* Save Button */}
          <div className="w-full flex justify-end">
            <button
              onClick={handleSave}
              disabled={isSaving}
              className={`w-full sm:w-auto group relative inline-flex items-center justify-center px-6 py-2.5 rounded-lg text-white font-medium shadow-lg transition-all duration-200 ease-in-out ${
                isSaving 
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 active:from-blue-700 active:to-blue-800'
              }`}
            >
              <FaSave 
                className={`w-5 h-5 mr-2 ${isSaving ? 'animate-spin' : ''}`}
              />
              {isSaving ? 'Saving...' : 'Save Resume'}
            </button>
          </div>

          {/* Dropdown Navigation */}
          <div className="w-full">
            <select
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
              className="w-full sm:w-auto bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg px-4 py-2.5 text-gray-700 dark:text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            >
              {tabs.map(tab => (
                <option key={tab.id} value={tab.id}>
                  {tab.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Toast Notification */}
        {saveStatus && (
          <div className="fixed bottom-4 right-4 left-4 sm:left-auto z-50">
            <div 
              className={`
                px-4 py-3 rounded-lg shadow-lg max-w-md mx-auto sm:mx-0
                transform transition-all duration-300 ease-in-out
                translate-y-0 opacity-100
                ${saveStatus.includes('Error') || saveStatus.includes('Validation') 
                  ? 'bg-red-500 text-white' 
                  : saveStatus === 'Saving...' 
                    ? 'bg-yellow-500 text-white'
                    : 'bg-green-500 text-white'
                }
              `}
            >
              <div className="flex items-center space-x-2">
                <svg 
                  className="w-5 h-5 flex-shrink-0" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  {saveStatus.includes('Error') || saveStatus.includes('Validation') ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  ) : saveStatus === 'Saving...' ? (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  )}
                </svg>
                <span className="font-medium text-sm sm:text-base">{saveStatus}</span>
              </div>
            </div>
          </div>
        )}

        {/* Content */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg mt-6">
          {activeTab === 'work' && (
            <WorkHistory 
              workHistory={workHistory} 
              setWorkHistory={setWorkHistory} 
            />
          )}
          {activeTab === 'education' && (
            <Education 
              education={education} 
              setEducation={setEducation} 
            />
          )}
          {activeTab === 'projects' && (
            <Projects 
              projects={projects} 
              setProjects={setProjects} 
            />
          )}
          {activeTab === 'skills' && (
            <Skills 
              skills={skills} 
              setSkills={setSkills} 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Resume;
