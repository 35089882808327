import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  FaLink, 
  FaYoutube, 
  FaMedium, 
  FaGithub, 
  FaTwitter, 
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTwitch,
  FaReddit,
  FaSpotify,
  FaSoundcloud,
  FaBehance,
  FaDribbble,
  FaWordpress,
  FaStackOverflow,
  FaCodepen,
  FaNpm,
  FaDiscord,
  FaTelegram,
  FaVimeo,
  FaPinterest,
  FaGitlab,
  FaBitbucket,
  FaTrello,
  FaJira,
  FaSlack,
  FaExternalLinkAlt
} from 'react-icons/fa';
import { 
  SiNotion, 
  SiDevdotto, 
  SiHashnode,
  SiNetlify,
  SiVercel,
  SiFirebase
} from 'react-icons/si';
import Modal from '../common/Modal';

const ProfileExtraLinks = ({ extraLinks, userModules }) => {
  const [linksWithMetadata, setLinksWithMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

  // Extract YouTube video ID from URL
  const getYoutubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  // Get service type from URL
  const getServiceType = (url) => {
    const hostname = url.hostname.toLowerCase();
    
    // Social Media & Content
    if (hostname.includes('youtube.com') || hostname.includes('youtu.be')) return 'youtube';
    if (hostname.includes('github.com')) return 'github';
    if (hostname.includes('medium.com')) return 'medium';
    if (hostname.includes('twitter.com') || hostname.includes('x.com')) return 'twitter';
    if (hostname.includes('linkedin.com')) return 'linkedin';
    if (hostname.includes('instagram.com')) return 'instagram';
    if (hostname.includes('facebook.com')) return 'facebook';
    if (hostname.includes('twitch.tv')) return 'twitch';
    if (hostname.includes('reddit.com')) return 'reddit';
    if (hostname.includes('pinterest.com')) return 'pinterest';
    
    // Development & Tech
    if (hostname.includes('stackoverflow.com')) return 'stackoverflow';
    if (hostname.includes('codepen.io')) return 'codepen';
    if (hostname.includes('npmjs.com')) return 'npm';
    if (hostname.includes('dev.to')) return 'devto';
    if (hostname.includes('hashnode.com')) return 'hashnode';
    if (hostname.includes('gitlab.com')) return 'gitlab';
    if (hostname.includes('bitbucket.org')) return 'bitbucket';
    if (hostname.includes('netlify.com')) return 'netlify';
    if (hostname.includes('vercel.com')) return 'vercel';
    if (hostname.includes('firebase.google.com')) return 'firebase';
    
    // Creative & Design
    if (hostname.includes('behance.net')) return 'behance';
    if (hostname.includes('dribbble.com')) return 'dribbble';
    if (hostname.includes('vimeo.com')) return 'vimeo';
    
    // Music & Audio
    if (hostname.includes('spotify.com')) return 'spotify';
    if (hostname.includes('soundcloud.com')) return 'soundcloud';
    
    // Productivity & Communication
    if (hostname.includes('notion.so')) return 'notion';
    if (hostname.includes('wordpress.com')) return 'wordpress';
    if (hostname.includes('slack.com')) return 'slack';
    if (hostname.includes('discord.com')) return 'discord';
    if (hostname.includes('telegram.org')) return 'telegram';
    if (hostname.includes('trello.com')) return 'trello';
    if (hostname.includes('atlassian.net')) {
      if (url.pathname.includes('jira')) return 'jira';
    }

    return 'link';
  };

  // Get metadata based on URL patterns
  const getLinkMetadata = async (link) => {
    const url = new URL(link.url);
    const type = getServiceType(url);
    
    // YouTube
    if (type === 'youtube') {
      const videoId = getYoutubeVideoId(link.url);
      if (videoId) {
        return {
          type,
          title: link.title,
          image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
          fallbackImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
          description: 'YouTube Video',
          videoId
        };
      }
    }
    
    // GitHub
    if (type === 'github') {
      try {
        const parts = url.pathname.split('/').filter(Boolean);
        if (parts.length >= 2) {
          const response = await fetch(`https://api.github.com/repos/${parts[0]}/${parts[1]}`);
          const data = await response.json();
          
          return {
            type,
            title: data.full_name || link.title,
            description: data.description || 'GitHub Repository',
            image: data.owner?.avatar_url || '',
            stats: {
              stars: data.stargazers_count,
              forks: data.forks_count,
              language: data.language
            }
          };
        }
      } catch (error) {
        console.error('Error fetching GitHub metadata:', error);
      }
    }

    // Default - Try to fetch OpenGraph metadata
    try {
      const response = await fetch(`https://api.microlink.io?url=${encodeURIComponent(link.url)}`);
      const data = await response.json();
      
      if (data.status === 'success') {
        return {
          type,
          title: data.data.title || link.title,
          description: data.data.description || url.hostname.replace('www.', ''),
          image: data.data.image?.url || data.data.logo?.url || '',
          favicon: data.data.logo?.url || ''
        };
      }
    } catch (error) {
      console.error('Error fetching OpenGraph metadata:', error);
    }

    // Fallback
    return {
      type,
      title: link.title,
      description: url.hostname.replace('www.', ''),
      image: ''
    };
  };

  useEffect(() => {
    if (!extraLinks?.length) {
      setLoading(false);
      return;
    }

    // Process all links
    const processLinks = async () => {
      try {
        const processedLinks = await Promise.all(
          extraLinks.map(async (link) => ({
            ...link,
            metadata: await getLinkMetadata(link)
          }))
        );

        setLinksWithMetadata(processedLinks);
      } catch (error) {
        console.error('Error processing links:', error);
      } finally {
        setLoading(false);
      }
    };

    processLinks();
  }, [extraLinks]);

  const handleLinkClick = (e, link) => {
    if (link.metadata.type === 'youtube' && link.metadata.videoId) {
      e.preventDefault();
      setCurrentVideoId(link.metadata.videoId);
      setIsVideoModalOpen(true);
    }
  };

  const getIcon = (type) => {
    const iconClass = "w-5 h-5 flex-shrink-0";
    
    switch (type?.toLowerCase()) {
      // Social Media & Content
      case 'youtube': return <FaYoutube className={`${iconClass} text-red-500`} />;
      case 'github': return <FaGithub className={`${iconClass} text-gray-900 dark:text-gray-100`} />;
      case 'medium': return <FaMedium className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'twitter': return <FaTwitter className={`${iconClass} text-blue-400`} />;
      case 'linkedin': return <FaLinkedin className={`${iconClass} text-blue-600`} />;
      case 'instagram': return <FaInstagram className={`${iconClass} text-pink-500`} />;
      case 'facebook': return <FaFacebook className={`${iconClass} text-blue-600`} />;
      case 'twitch': return <FaTwitch className={`${iconClass} text-purple-500`} />;
      case 'reddit': return <FaReddit className={`${iconClass} text-orange-500`} />;
      case 'pinterest': return <FaPinterest className={`${iconClass} text-red-500`} />;
      
      // Development & Tech
      case 'stackoverflow': return <FaStackOverflow className={`${iconClass} text-orange-500`} />;
      case 'codepen': return <FaCodepen className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'npm': return <FaNpm className={`${iconClass} text-red-500`} />;
      case 'devto': return <SiDevdotto className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'hashnode': return <SiHashnode className={`${iconClass} text-blue-500`} />;
      case 'gitlab': return <FaGitlab className={`${iconClass} text-orange-500`} />;
      case 'bitbucket': return <FaBitbucket className={`${iconClass} text-blue-500`} />;
      case 'netlify': return <SiNetlify className={`${iconClass} text-blue-500`} />;
      case 'vercel': return <SiVercel className={`${iconClass} text-black dark:text-white`} />;
      case 'firebase': return <SiFirebase className={`${iconClass} text-orange-500`} />;
      
      // Creative & Design
      case 'behance': return <FaBehance className={`${iconClass} text-blue-600`} />;
      case 'dribbble': return <FaDribbble className={`${iconClass} text-pink-500`} />;
      case 'vimeo': return <FaVimeo className={`${iconClass} text-blue-600`} />;
      
      // Music & Audio
      case 'spotify': return <FaSpotify className={`${iconClass} text-green-500`} />;
      case 'soundcloud': return <FaSoundcloud className={`${iconClass} text-orange-500`} />;
      
      // Productivity & Communication
      case 'notion': return <SiNotion className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'wordpress': return <FaWordpress className={`${iconClass} text-blue-500`} />;
      case 'slack': return <FaSlack className={`${iconClass} text-purple-500`} />;
      case 'discord': return <FaDiscord className={`${iconClass} text-indigo-500`} />;
      case 'telegram': return <FaTelegram className={`${iconClass} text-blue-500`} />;
      case 'trello': return <FaTrello className={`${iconClass} text-blue-500`} />;
      case 'jira': return <FaJira className={`${iconClass} text-blue-500`} />;
      
      // Default
      default: return <FaLink className={`${iconClass} text-gray-500 dark:text-gray-400`} />;
    }
  };

  if (!userModules?.extraLinks || !extraLinks?.length) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mt-8 bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6"
    >
      <div className="flex items-center gap-2 mb-6">
        <FaLink className="w-5 h-5 text-blue-500 dark:text-blue-400" />
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Featured Links
        </h3>
      </div>

      {loading ? (
        // Loading skeleton
        <div className="space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="animate-pulse flex h-32">
              <div className="w-48 bg-gray-200 dark:bg-gray-700 rounded-l-lg"></div>
              <div className="flex-1 p-4">
                <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 mb-2"></div>
                <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="space-y-4">
          {linksWithMetadata.map((link, index) => (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => handleLinkClick(e, link)}
              className="group flex overflow-hidden rounded-lg border border-gray-200 dark:border-gray-700 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300 h-32"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
            >
              {link.metadata.image && (
                <div className="relative w-48 overflow-hidden bg-gray-100 dark:bg-gray-800">
                  <img
                    src={link.metadata.image}
                    alt={link.metadata.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    onError={(e) => {
                      if (link.metadata.fallbackImage && e.target.src !== link.metadata.fallbackImage) {
                        e.target.src = link.metadata.fallbackImage;
                      } else {
                        e.target.style.display = 'none';
                      }
                    }}
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                </div>
              )}
              
              <div className="flex-1 p-4 flex flex-col justify-center">
                <div className="flex items-center gap-2 mb-2">
                  {getIcon(link.metadata.type)}
                  <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                    <FaExternalLinkAlt className="w-3 h-3 mr-2" />
                    <span className="truncate">
                      {new URL(link.url).hostname.replace('www.', '')}
                    </span>
                  </div>
                </div>
                
                <h4 className="font-medium text-gray-900 dark:text-white mb-2 line-clamp-2 group-hover:text-blue-500 dark:group-hover:text-blue-400 transition-colors">
                  {link.metadata.title}
                </h4>
                
                {link.metadata.description && (
                  <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-1">
                    {link.metadata.description}
                  </p>
                )}

                {link.metadata.stats && (
                  <div className="flex items-center gap-4 mt-2 text-sm text-gray-500 dark:text-gray-400">
                    {link.metadata.stats.stars && (
                      <span>⭐ {link.metadata.stats.stars.toLocaleString()}</span>
                    )}
                    {link.metadata.stats.forks && (
                      <span>🔱 {link.metadata.stats.forks.toLocaleString()}</span>
                    )}
                    {link.metadata.stats.language && (
                      <span>📝 {link.metadata.stats.language}</span>
                    )}
                  </div>
                )}
              </div>
            </motion.a>
          ))}
        </div>
      )}

      {/* YouTube Video Modal */}
      <Modal
        isOpen={isVideoModalOpen}
        onClose={() => {
          setIsVideoModalOpen(false);
          setCurrentVideoId(null);
        }}
        title="YouTube Video"
      >
        <div className="relative pb-[56.25%] h-0">
          <iframe
            src={`https://www.youtube.com/embed/${currentVideoId}`}
            title="YouTube video player"
            className="absolute top-0 left-0 w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </motion.div>
  );
};

export default ProfileExtraLinks;
