import React, { createContext, useContext, useState, useEffect } from 'react';
import { authApi, setAuthToken } from '../services/api';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();

  // Function to fetch user profile
  const fetchUserProfile = async () => {
    try {
      const response = await authApi.get('/api/user');
      if (response.data) {
        setUser(response.data);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  // Check for existing authentication
  const checkAuth = async () => {
    try {
      // Check for token in storage (localStorage has priority)
      const token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
      
      if (token) {
        // Set the token in axios headers
        setAuthToken(token);
        
        // Fetch user profile to validate token
        const isValid = await fetchUserProfile();
        
        if (!isValid) {
          // If profile fetch fails, clear everything
          localStorage.removeItem('auth_token');
          sessionStorage.removeItem('auth_token');
          setAuthToken(null);
          setUser(null);
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      // Clear everything on error
      localStorage.removeItem('auth_token');
      sessionStorage.removeItem('auth_token');
      setAuthToken(null);
      setUser(null);
    }
  };

  // Run auth check on mount and when token changes
  useEffect(() => {
    const token = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');
    if (token) {
      checkAuth().finally(() => setInitialLoading(false));
    } else {
      setInitialLoading(false);
    }
  }, []);

  const login = async (email, password, remember = false) => {
    try {
      setError(null);
      const response = await authApi.login(email, password, remember);
      const { user, token } = response.data;

      // Store auth data
      if (remember) {
        localStorage.setItem('auth_token', token);
        sessionStorage.removeItem('auth_token'); // Clear session storage
      } else {
        sessionStorage.setItem('auth_token', token);
        localStorage.removeItem('auth_token'); // Clear local storage
      }
      
      setAuthToken(token);
      setUser(user);

      return { success: true };
    } catch (error) {
      const message = error.response?.data?.message || 'Failed to login';
      setError(message);
      // Don't clear tokens or navigate on login failure
      return {
        success: false,
        error: message
      };
    }
  };

  const register = async (userData) => {
    try {
      setError(null);
      const response = await authApi.register(userData);
      const { user, token } = response.data;

      // Store auth data in session storage by default for new registrations
      sessionStorage.setItem('auth_token', token);
      localStorage.removeItem('auth_token'); // Clear local storage
      setAuthToken(token);
      setUser(user);

      return { success: true };
    } catch (error) {
      const message = error.response?.data?.message || 'Failed to register';
      setError(message);
      return {
        success: false,
        error: message
      };
    }
  };

  const logout = async (redirectToAbout = true) => {
    try {
      await authApi.logout();
    } catch (error) {
      // Ignore logout errors
    } finally {
      // Clear auth token from both storages
      localStorage.removeItem('auth_token');
      sessionStorage.removeItem('auth_token');
      
      // Clear auth token from API headers
      setAuthToken(null);
      
      // Clear user state
      setUser(null);
      
      // Clear any errors
      setError(null);

      // Only navigate to about page if explicitly requested
      if (redirectToAbout) {
        navigate('/about');
      }
    }
  };

  const value = {
    user,
    error,
    loading: initialLoading,
    login,
    register,
    logout,
    checkAuth
  };

  if (initialLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
