import React, { useState, useRef, useCallback, useEffect } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { useAuth } from '../../contexts/AuthContext';
import Modal from '../common/Modal';
import Controls from './components/Controls';
import NodeRenderer from './components/NodeRenderer';
import useNetworkData from './hooks/useNetworkData';
import useGraphDimensions from './hooks/useGraphDimensions';
import { getColors, getLinkColors } from './utils/colors';

const NetworkMap = () => {
    const containerRef = useRef(null);
    const fgRef = useRef();
    const dimensions = useGraphDimensions(containerRef);
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUsername, setSelectedUsername] = useState(null);
    const [isLegendOpen, setIsLegendOpen] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(document.documentElement.classList.contains('dark'));
    const [lastClickedNode, setLastClickedNode] = useState(null);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [visibleLinks, setVisibleLinks] = useState(new Set()); // Local state for visible links
    const [lastExpandedNode, setLastExpandedNode] = useState(null); // Track last expanded node
    const [processedGraphData, setProcessedGraphData] = useState({ nodes: [], links: [] });

    const { user } = useAuth();
    const currentUserId = user?.id;

    const {
        graphData,
        loading,
        error,
        expandedNodes,
        setExpandedNodes,
        connections
    } = useNetworkData(currentUserId, user, dimensions);

    // Process graph data to include connection counts
    useEffect(() => {
        if (graphData.nodes && graphData.links && connections) {
            const connectionCounts = new Map();
            
            // Count all connections for each node
            connections.forEach(conn => {
                if (conn.users && conn.users.length === 2) {
                    const [user1, user2] = conn.users;
                    connectionCounts.set(user1.id, (connectionCounts.get(user1.id) || 0) + 1);
                    connectionCounts.set(user2.id, (connectionCounts.get(user2.id) || 0) + 1);
                }
            });

            // Add counts to nodes
            const processedNodes = graphData.nodes.map(node => ({
                ...node,
                approvedConnections: connectionCounts.get(node.id) || 0
            }));

            setProcessedGraphData({
                nodes: processedNodes,
                links: graphData.links
            });
        }
    }, [graphData, connections]);

    // Initialize direct connections when graph data loads
    useEffect(() => {
        if (user && graphData.links && graphData.links.length > 0) {
            // Find all direct connection links and show them by default
            const directLinks = graphData.links.filter(link => link.isDirectConnection);
            setVisibleLinks(new Set(directLinks.map(link => link.id)));
        }
    }, [user, graphData.links]);

    // Listen for dark mode changes
    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === 'class') {
                    setIsDarkMode(document.documentElement.classList.contains('dark'));
                }
            });
        });

        observer.observe(document.documentElement, {
            attributes: true,
            attributeFilter: ['class']
        });

        return () => observer.disconnect();
    }, []);

    const colors = getColors(isDarkMode);
    const linkColors = getLinkColors(isDarkMode);

    const isDirectConnection = useCallback((nodeId) => {
        return graphData.links.some(link => 
            link.isDirectConnection && 
            (link.source.id === nodeId || link.target.id === nodeId)
        );
    }, [graphData.links]);

    const handleNodeClick = useCallback((node) => {
        // If clicking center node or node is invalid, do nothing
        if (!node || node.name === 'You') return;

        const currentTime = new Date().getTime();
        const isDoubleClick = node.id === lastClickedNode?.id && currentTime - lastClickTime < 300;

        if (isDoubleClick) {
            // Show profile on double click
            setSelectedUsername(node.username);
            setIsModalOpen(true);
        } else {
            // For logged-in users, only allow expanding direct connections
            // For logged-out users, allow expanding any node
            if (user && !isDirectConnection(node.id)) {
                setSelectedUsername(node.username);
                setIsModalOpen(true);
                return;
            }

            // Get all links connected to this node
            const nodeLinks = graphData.links.filter(link => 
                link.source.id === node.id || link.target.id === node.id
            );

            // Separate direct and secondary connections
            const secondaryLinks = nodeLinks.filter(link => !link.isDirectConnection);

            // If clicking the same node that's already expanded, collapse it
            if (lastExpandedNode === node.id) {
                setExpandedNodes(new Set());
                setVisibleLinks(prev => {
                    const newLinks = new Set(prev);
                    // Keep direct connections, remove secondary ones
                    secondaryLinks.forEach(link => newLinks.delete(link.id));
                    return newLinks;
                });
                setLastExpandedNode(null);
            } else {
                // If expanding a new node, collapse the previous one first
                setExpandedNodes(new Set([node.id]));
                setVisibleLinks(prev => {
                    const newLinks = new Set(prev);
                    
                    // Remove secondary connections from previously expanded node
                    if (lastExpandedNode) {
                        const prevNodeLinks = graphData.links.filter(link => 
                            (link.source.id === lastExpandedNode || link.target.id === lastExpandedNode) &&
                            !link.isDirectConnection
                        );
                        prevNodeLinks.forEach(link => newLinks.delete(link.id));
                    }
                    
                    // Always keep direct connection links
                    const directLinks = nodeLinks.filter(link => link.isDirectConnection);
                    directLinks.forEach(link => newLinks.add(link.id));
                    
                    // Add secondary links for the new node
                    secondaryLinks.forEach(link => newLinks.add(link.id));
                    
                    return newLinks;
                });
                setLastExpandedNode(node.id);
            }
        }

        setLastClickedNode(node);
        setLastClickTime(currentTime);
    }, [lastClickedNode, lastClickTime, graphData.links, setExpandedNodes, visibleLinks, user, isDirectConnection, lastExpandedNode]);

    const handleNodeDrag = useCallback((node) => {
        // Remove fixed position when node is dragged
        delete node.fx;
        delete node.fy;
    }, []);

    if (loading) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-800">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-800">
                <div className="text-red-500 dark:text-red-400">{error}</div>
            </div>
        );
    }

    // Get the current origin (protocol + hostname + port)
    const origin = window.location.origin;

    return (
        <div className="fixed inset-0 flex flex-col bg-white dark:bg-gray-800 overflow-hidden">
            {!user && (
                <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 bg-blue-100 dark:bg-blue-900 px-4 py-2 rounded-lg shadow-md">
                    <p className="text-blue-800 dark:text-blue-200 text-sm">
                        Click any node to see its connections • Double-click for profile
                    </p>
                </div>
            )}
            
            <Controls
                isLegendOpen={isLegendOpen}
                setIsLegendOpen={setIsLegendOpen}
                colors={colors}
                user={user}
            />
            
            <div 
                ref={containerRef}
                className="flex-1 w-full h-full"
            >
                <ForceGraph2D
                    ref={fgRef}
                    graphData={processedGraphData}
                    width={dimensions.width}
                    height={dimensions.height}
                    nodeLabel="name"
                    nodeRelSize={10}
                    linkWidth={link => {
                        if (!link || !link.id || !visibleLinks.has(link.id)) return 0;
                        return link.isDirectConnection ? 
                            (dimensions.width <= 768 ? 3 : 4) : 
                            (dimensions.width <= 768 ? 2 : 3);
                    }}
                    linkColor={link => {
                        if (!link || !link.id || !visibleLinks.has(link.id)) return 'rgba(0,0,0,0)';
                        return link.isDirectConnection ? linkColors.direct : linkColors.secondary;
                    }}
                    backgroundColor="transparent"
                    onNodeClick={handleNodeClick}
                    onNodeDrag={handleNodeDrag}
                    nodeCanvasObjectMode={() => 'after'}
                    nodeCanvasObject={(node, ctx, globalScale) => 
                        NodeRenderer(node, ctx, globalScale, colors, isDarkMode, expandedNodes)
                    }
                    cooldownTicks={0}
                    enableNodeDrag={true}
                    enableZoom={true}
                    minZoom={0.5}
                    maxZoom={8}
                    enablePanInteraction={true}
                    linkCurvature={link => {
                        if (!link || !link.source || !link.target) return 0;
                        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
                        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
                        return sourceId < targetId ? 0.1 : -0.1;
                    }}
                    linkDirectionalParticles={link => {
                        if (!link || !link.id || !visibleLinks.has(link.id)) return 0;
                        return link.isDirectConnection ? 
                            (dimensions.width <= 768 ? 4 : 6) : 
                            (dimensions.width <= 768 ? 3 : 4);
                    }}
                    linkDirectionalParticleWidth={link => {
                        if (!link || !link.id || !visibleLinks.has(link.id)) return 0;
                        return link.isDirectConnection ? 
                            (dimensions.width <= 768 ? 3 : 4.5) : 
                            (dimensions.width <= 768 ? 2 : 3.5);
                    }}
                    linkDirectionalParticleSpeed={0.003}
                    linkDirectionalParticleColor={link => 
                        link.isDirectConnection ? linkColors.direct : linkColors.secondary
                    }
                />
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedUsername(null);
                }}
                title="User Profile"
            >
                {selectedUsername && (
                    <div className="w-full h-[80vh]">
                        <iframe
                            src={`${origin}/minimal-profile/${selectedUsername}`}
                            className="w-full h-full border-0"
                            title="User Profile"
                        />
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default NetworkMap;
