import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  HiOutlineCode,
  HiOutlineDatabase,
  HiOutlineTemplate,
  HiOutlineCog,
  HiOutlineShieldCheck,
  HiOutlineLightningBolt,
  HiOutlineCloud,
  HiOutlineServer,
  HiArrowRight,
  HiOutlineCheck,
  HiOutlineGlobe,
  HiOutlineUserCircle,
  HiOutlinePuzzle,
  HiOutlineLink,
  HiChevronDown,
  HiShare,
  HiColorSwatch
} from 'react-icons/hi';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../services/api';

function About({ onAuthClick }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [pingStatus, setPingStatus] = useState(null);
  const [pingTime, setPingTime] = useState(null);
  const [isPinging, setIsPinging] = useState(false);
  const [isDevSectionOpen, setIsDevSectionOpen] = useState(false);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardHover = {
    scale: 1.02,
    transition: { duration: 0.2 }
  };

  const testBackendConnection = async () => {
    setIsPinging(true);
    setPingStatus(null);
    setPingTime(null);
    
    const startTime = performance.now();
    
    try {
      await authApi.test();
      const endTime = performance.now();
      setPingStatus('success');
      setPingTime(Math.round(endTime - startTime));
    } catch (error) {
      setPingStatus('error');
      console.error('Connection test failed:', error);
    } finally {
      setIsPinging(false);
    }
  };

  const keyFeatures = [
    {
      icon: HiOutlineGlobe,
      title: "One Link for Everything",
      description: "Share a single, memorable link that connects people to all your content and profiles."
    },
    {
      icon: HiOutlineUserCircle,
      title: "Tell Your Story",
      description: "Create a beautiful personal page that showcases who you are and what you do best."
    },
    {
      icon: HiShare,
      title: "Network Map",
      description: "Explore and visualize your professional network with our interactive Network Map. See how connections interlink and discover new opportunities for collaboration."
    },
    {
      icon: HiOutlinePuzzle,
      title: "Modular Design",
      description: "Choose from a variety of modules to build the perfect page that fits your needs."
    },
    {
      icon: HiOutlineLink,
      title: "Connect Everything",
      description: "Bring together all your important links and content in one customizable space."
    },
    {
      icon: HiColorSwatch,
      title: "Personal Branding",
      description: "Customize your page's appearance with themes, colors, and layouts that match your personal brand identity."
    }
  ];

  const techStack = [
    {
      icon: HiOutlineCloud,
      title: "AWS Infrastructure",
      description: "Deployed on AWS Elastic Beanstalk with EC2 instances for scalable hosting. Leverages AWS services for reliable, production-grade infrastructure."
    },
    {
      icon: HiOutlineCode,
      title: "React Frontend",
      description: "Modern React application utilizing hooks, context API, and functional components. Enhanced with TailwindCSS for responsive, utility-first styling."
    },
    {
      icon: HiOutlineDatabase,
      title: "Laravel Backend",
      description: "Powered by Laravel 10 PHP framework providing a robust RESTful API architecture, with efficient database management and business logic implementation."
    },
    {
      icon: HiOutlineShieldCheck,
      title: "Security First",
      description: "Implements JWT authentication, CSRF protection, and secure HTTP-only cookies. All API endpoints are protected with middleware validation."
    }
  ];

  const technicalFeatures = [
    {
      icon: HiOutlineServer,
      title: "Cloud Architecture",
      description: "AWS Elastic Beanstalk orchestrates EC2 instances, load balancing, and auto-scaling for optimal performance and reliability."
    },
    {
      icon: HiOutlineCog,
      title: "Full-Stack Integration",
      description: "Seamless integration between React frontend and Laravel PHP backend, with RESTful API endpoints and efficient state management."
    },
    {
      icon: HiOutlineLightningBolt,
      title: "Performance Optimized",
      description: "Leverages AWS infrastructure capabilities combined with application-level optimizations for fast, reliable performance."
    }
  ];

  const architectureHighlights = [
    {
      title: "Cloud Infrastructure",
      points: [
        "AWS Elastic Beanstalk",
        "EC2 instance deployment",
        "Auto-scaling configuration",
        "Load balancing"
      ]
    },
    {
      title: "Application Stack",
      points: [
        "React 18 frontend",
        "Laravel 10 PHP backend",
        "RESTful API architecture",
        "TailwindCSS styling"
      ]
    },
    {
      title: "DevOps & Security",
      points: [
        "AWS deployment pipeline",
        "Environment management",
        "SSL/TLS encryption",
        "Security best practices"
      ]
    }
  ];

  const handleGetStarted = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      onAuthClick('register');
    }
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      className="max-w-7xl mx-auto px-4 py-12"
    >
      {/* Hero Section */}
      <motion.div 
        variants={fadeInUp}
        className="text-center mb-20"
      >
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-6xl font-bold text-gray-900 dark:text-white mb-6 leading-tight"
        >
          Share Everything with <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
            className="text-blue-600 dark:text-blue-400"
          >Just One Link</motion.span>
        </motion.h1>
        <motion.p 
          variants={fadeInUp}
          className="text-2xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed mb-8"
        >
          Stop sharing multiple links. Create one beautiful page that brings together all your content and important links in a customizable space.
        </motion.p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleGetStarted}
          className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          Create Your Free Page
          <motion.div
            animate={{ x: [0, 5, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <HiArrowRight className="ml-2 h-5 w-5" />
          </motion.div>
        </motion.button>
      </motion.div>

      {/* Key Features Section */}
      <motion.section 
        variants={staggerContainer}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        className="mb-20"
      >
        <motion.h2 
          variants={fadeInUp}
          className="text-4xl font-bold text-gray-900 dark:text-white mb-12 text-center"
        >
          Why People Love It
        </motion.h2>
        <motion.div 
          variants={staggerContainer}
          className="grid md:grid-cols-3 gap-8"
        >
          {keyFeatures.map((feature, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              whileHover={cardHover}
              className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg border border-gray-100 dark:border-gray-700 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <motion.div 
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.6 }}
                  className="p-3 bg-blue-100 dark:bg-blue-900 rounded-lg"
                >
                  <feature.icon className="h-8 w-8 text-blue-600 dark:text-blue-400" />
                </motion.div>
                <h3 className="text-2xl font-semibold text-gray-900 dark:text-white ml-4">
                  {feature.title}
                </h3>
              </div>
              <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </motion.section>

      {/* For Developers Section */}
      <motion.div className="mb-20">
        <motion.button
          onClick={() => setIsDevSectionOpen(!isDevSectionOpen)}
          className="w-full flex items-center justify-between p-4 bg-gray-100 dark:bg-gray-800 rounded-lg mb-6 hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
        >
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Technical Specs</h2>
          <motion.div
            animate={{ rotate: isDevSectionOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <HiChevronDown className="h-6 w-6 text-gray-600 dark:text-gray-300" />
          </motion.div>
        </motion.button>

        {isDevSectionOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-12 text-center">
              Built with modern technologies and best practices, our platform offers a robust foundation for your digital presence.
            </motion.p>

            {/* Tech Stack Section */}
            <motion.section 
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="mb-20"
            >
              <motion.h2 
                variants={fadeInUp}
                className="text-4xl font-bold text-gray-900 dark:text-white mb-12 text-center"
              >
                Technical Stack Overview
              </motion.h2>
              <motion.div 
                variants={staggerContainer}
                className="grid md:grid-cols-2 gap-8"
              >
                {techStack.map((tech, index) => (
                  <motion.div
                    key={index}
                    variants={fadeInUp}
                    whileHover={cardHover}
                    className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg border border-gray-100 dark:border-gray-700 hover:shadow-xl transition-shadow duration-300"
                  >
                    <div className="flex items-center mb-4">
                      <motion.div 
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.6 }}
                        className="p-3 bg-blue-100 dark:bg-blue-900 rounded-lg"
                      >
                        <tech.icon className="h-8 w-8 text-blue-600 dark:text-blue-400" />
                      </motion.div>
                      <h3 className="text-2xl font-semibold text-gray-900 dark:text-white ml-4">
                        {tech.title}
                      </h3>
                    </div>
                    <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                      {tech.description}
                    </p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.section>

            {/* Technical Features Section */}
            <motion.section 
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="mb-20 bg-gradient-to-r from-blue-50 to-indigo-50 dark:from-gray-800 dark:to-gray-900 rounded-2xl p-12"
            >
              <motion.h2 
                variants={fadeInUp}
                className="text-4xl font-bold text-gray-900 dark:text-white mb-12 text-center"
              >
                Implementation Details
              </motion.h2>
              <motion.div 
                variants={staggerContainer}
                className="grid md:grid-cols-3 gap-8"
              >
                {technicalFeatures.map((feature, index) => (
                  <motion.div
                    key={index}
                    variants={fadeInUp}
                    whileHover={cardHover}
                    className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-md"
                  >
                    <div className="flex items-center mb-4">
                      <motion.div
                        whileHover={{ rotate: 360 }}
                        transition={{ duration: 0.6 }}
                      >
                        <feature.icon className="h-8 w-8 text-green-600 dark:text-green-400" />
                      </motion.div>
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-white ml-3">
                        {feature.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300">
                      {feature.description}
                    </p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.section>

            {/* Architecture Section */}
            <motion.section 
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="mb-20"
            >
              <motion.h2 
                variants={fadeInUp}
                className="text-4xl font-bold text-gray-900 dark:text-white mb-12 text-center"
              >
                Architecture Breakdown
              </motion.h2>
              <motion.div 
                variants={staggerContainer}
                className="grid md:grid-cols-3 gap-8"
              >
                {architectureHighlights.map((arch, index) => (
                  <motion.div
                    key={index}
                    variants={fadeInUp}
                    whileHover={cardHover}
                    className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg border border-gray-100 dark:border-gray-700"
                  >
                    <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
                      {arch.title}
                    </h3>
                    <motion.ul 
                      variants={staggerContainer}
                      className="space-y-4"
                    >
                      {arch.points.map((point, pointIndex) => (
                        <motion.li
                          key={pointIndex}
                          variants={fadeInUp}
                          className="flex items-center text-gray-600 dark:text-gray-300"
                        >
                          <motion.div
                            whileHover={{ scale: 1.2 }}
                            className="mr-3"
                          >
                            <HiOutlineCheck className="h-5 w-5 text-green-500" />
                          </motion.div>
                          {point}
                        </motion.li>
                      ))}
                    </motion.ul>
                  </motion.div>
                ))}
              </motion.div>
            </motion.section>
          </motion.div>
        )}
      </motion.div>

      {/* CTA Section */}
      <motion.section 
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        className="bg-blue-600 dark:bg-blue-700 rounded-2xl p-12 text-center"
      >
        <motion.h2 
          variants={fadeInUp}
          className="text-4xl font-bold text-white mb-6"
        >
          Ready to Simplify Your Online Presence?
        </motion.h2>
        <motion.p 
          variants={fadeInUp}
          className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto"
        >
          Create your page now and start sharing your story with the world.
        </motion.p>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleGetStarted}
          className="inline-flex items-center px-8 py-4 text-lg font-semibold text-blue-600 bg-white rounded-lg hover:bg-gray-100 transition-colors duration-200"
        >
          Get Started - It's Free
          <motion.div
            animate={{ x: [0, 5, 0] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          >
            <HiArrowRight className="ml-2 h-5 w-5" />
          </motion.div>
        </motion.button>
      </motion.section>
    </motion.div>
  );
}

export default About;
