import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { HiUserAdd } from 'react-icons/hi';
import { authApi, resumeApi, connectionApi, handleApiError } from '../services/api';
import ProfileBanner from '../components/profile/ProfileBanner';
import ProfileAboutSection from '../components/profile/ProfileAboutSection';
import ProfileSocialLinks from '../components/profile/ProfileSocialLinks';
import ProfilePaymentLinks from '../components/profile/ProfilePaymentLinks';
import ProfileResume from '../components/profile/ProfileResume';
import ProfileTeams from '../components/profile/ProfileTeams';
import ProfileExtraLinks from '../components/profile/ProfileExtraLinks';
import { useAuth } from '../contexts/AuthContext';
import { useAuthModal } from '../contexts/AuthModalContext';

const MinimalUserProfile = () => {
  const { username } = useParams();
  const { user: currentUser } = useAuth();
  const onAuthClick = useAuthModal();
  const [user, setUser] = useState(null);
  const [userModules, setUserModules] = useState({});
  const [resumeData, setResumeData] = useState(null);
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [extraLinks, setExtraLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('none');
  const [connectionId, setConnectionId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRequestReceiver, setIsRequestReceiver] = useState(false);

  // Connection handlers
  const handleConnect = async () => {
    try {
      setIsProcessing(true);
      const response = await connectionApi.sendRequest(user.id);
      setConnectionStatus('pending');
      setConnectionId(response.connection.id);
      setIsRequestReceiver(false);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAccept = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.acceptRequest(connectionId);
      setConnectionStatus('connected');
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDecline = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.declineRequest(connectionId);
      setConnectionStatus('none');
      setConnectionId(null);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsProcessing(true);
      await connectionApi.removeConnection(connectionId);
      setConnectionStatus('none');
      setConnectionId(null);
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setIsProcessing(false);
    }
  };

  // Get connection button configuration based on status and auth state
  const getConnectionButton = () => {
    const baseClasses = "w-full sm:w-auto mx-auto flex items-center justify-center gap-2 px-6 py-2.5 rounded-lg font-medium shadow-sm transition-all duration-200";
    
    if (!currentUser) {
      return {
        onClick: onAuthClick,
        icon: HiUserAdd,
        text: "Add Connection",
        classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
      };
    }

    if (currentUser?.username === username) {
      return null;
    }
    
    switch (connectionStatus) {
      case 'connected':
        return {
          onClick: handleRemove,
          icon: HiUserAdd,
          text: "Remove Connection",
          classes: `${baseClasses} bg-red-500 hover:bg-red-600 text-white`
        };
      case 'pending':
        return {
          onClick: null,
          icon: HiUserAdd,
          text: "Connection Pending",
          classes: `${baseClasses} bg-yellow-500 hover:bg-yellow-600 text-white cursor-not-allowed`
        };
      default:
        return {
          onClick: handleConnect,
          icon: HiUserAdd,
          text: "Add Connection",
          classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
        };
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Fetch user data, social links, and modules in parallel
        const [userProfile, socialLinksData, modulesData] = await Promise.all([
          authApi.getUserProfile(username),
          authApi.getUserSocialLinks(username),
          authApi.get(`/api/users/${username}/modules`)
        ]);

        // Format social links
        const formattedSocialLinks = (Array.isArray(socialLinksData) ? socialLinksData : [])
          .filter(link => link?.url?.trim())
          .map(link => ({
            url: link.url.trim().startsWith('http') ? link.url.trim() : `https://${link.url.trim()}`
          }));

        setUser({
          ...userProfile,
          socialLinks: formattedSocialLinks
        });

        setUserModules(modulesData.data?.modules || {});

        // Only fetch connection status if user is logged in
        if (currentUser) {
          try {
            const connectionsData = await connectionApi.getConnections();
            const connection = connectionsData.connections.find(
              conn => conn.requester_id === userProfile.id || conn.requested_id === userProfile.id
            );
            
            if (connection) {
              setConnectionId(connection.id);
              setConnectionStatus(connection.status === 'accepted' ? 'connected' : 'pending');
              setIsRequestReceiver(connection.requested_id === userProfile.id);
            }
          } catch (error) {
            console.error('Error fetching connection status:', error);
          }
        }

        // Fetch resume data if resume module is enabled
        if (modulesData.data?.modules?.resume) {
          try {
            const resume = await resumeApi.getResume(username);
            if (resume.data && Object.values(resume.data).some(section => section?.length > 0)) {
              setResumeData(resume.data);
            }
          } catch (error) {
            console.error('Error fetching resume:', error);
          }
        }

        // Fetch payment links if payment module is enabled
        if (modulesData.data?.modules?.payment) {
          try {
            const paymentData = await authApi.get(`/api/user/${username}/payment-links`);
            setPaymentLinks(paymentData.data?.paymentLinks || []);
          } catch (error) {
            console.error('Error fetching payment links:', error);
          }
        }

        // Fetch extra links if extraLinks module is enabled
        if (modulesData.data?.modules?.extraLinks) {
          try {
            const extraLinksData = await authApi.getExtraLinks(username);
            setExtraLinks(Array.isArray(extraLinksData) ? extraLinksData : []);
          } catch (error) {
            console.error('Error fetching extra links:', error);
          }
        }
      } catch (err) {
        setError(handleApiError(err));
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username, currentUser]);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-4 py-2 rounded">
          {error}
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="p-4">
        <div className="bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-4 py-2 rounded">
          User not found
        </div>
      </div>
    );
  }

  const connectionButton = getConnectionButton();

  return (
    <div className="p-4 bg-white dark:bg-gray-900 min-h-screen">
      <div className="max-w-2xl mx-auto">
        <ProfileBanner 
          user={user}
          connectionButton={connectionButton}
          connectionStatus={connectionStatus}
          connectionId={connectionId}
          isRequestReceiver={isRequestReceiver}
          isProcessing={isProcessing}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
        />

        <ProfileAboutSection 
          company={user.company}
          about={user.about}
        />

        <ProfileSocialLinks 
          socialLinks={user.socialLinks}
        />

        <ProfilePaymentLinks 
          paymentLinks={paymentLinks}
        />

        <ProfileExtraLinks 
          extraLinks={extraLinks}
          userModules={userModules}
        />

        <ProfileResume 
          resumeData={resumeData}
          userModules={userModules}
        />

        <ProfileTeams 
          teams={user.teams}
        />
      </div>
    </div>
  );
};

export default MinimalUserProfile;
